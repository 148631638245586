nop
<template>
  <div class="posts">
    <div class="navigation">
      <div class="navigation__item">
        <router-link to="/sr/pages">
          Pages
        </router-link>
      </div>
      <div class="navigation__item">
        <router-link to="/sr/pages/trash">
          Trash
        </router-link>
      </div>
    </div>
    <div class="posts_container">
      <v-app-bar app>
        <font-awesome-icon :icon="['fas', 'copy']" class="navbar_icon"/>
        Pages
      </v-app-bar>
      <div class="languages">
        <p class="languages__title">Language:</p>
        <router-link v-for="(lang, index) in allLangs"  :to="'/' + lang + '/pages'" :key="index" class="navigation_menu">
          {{ lang }}
        </router-link>
      </div>
      <div class="container">
        <div class="row post_form_title">
          <div class="col-md-3 post_form_title__columns">
            <p>Title</p>
          </div>
          <div class="col-md-3 post_form_title__columns">
            <p>Date</p>
          </div>
          <div class="col-md-3 post_form_title__columns">
            <p>Status</p>
          </div>
          <div class="col-md-3 post_form_title__columns">
            <p>Languages</p>
          </div>
        </div>
      </div>
      <div class="posts_list">
        <div v-for="(page, index) in pages" :key="index">
          <div v-for="(translated_page, index2) in page.page" :key="index2">
            <div class="row single_post_row">
              <div class="col-md-3">
                <h3 class="posts_list__title">{{ translated_page.title }}
                 <span v-if="translated_page.main_page_id == frontPageID" class="front_page_notification">
                    ( front page )
                  </span></h3>
                <div class="edit_link">
                  <div class="edit_link__div">
                    <router-link v-bind:to="'/' + translated_page.lang + '/page/' + translated_page.main_page_id">
                      Edit page
                    </router-link>
                  </div>
                  <div v-if="translated_page.main_page_id != frontPageID" class="edit_link__div">
                    <a @click="deletePage('pages', translated_page.lang, translated_page.id); notificationMessage=true">Delete Page</a>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <p>{{ translated_page.created_at | formatDate }}</p>
              </div>
              <div class="col-md-3">
                <p>{{ translated_page.status.name }}</p>
              </div>
              <div class="col-md-3">
                <div class="languages__flag">
                    <img src="../../assets/srpska-zastava-jezik.jpg" alt="">
                    <img src="../../assets/bosnian-flag.jpg" alt="">
                    <img src="../../assets/turkish-flag.jpg" alt="">
                    <img src="../../assets/gorani-flag.jpg" alt="">
                    <img src="../../assets/romska-flag.jpg" alt="">
                    <img src="../../assets/alb-flag.png" alt="">
                  </div>
                <div class="add_or_edit_post_language">
                  <div v-for="(language, language_index) in page.languages" :key="language_index"
                       class="add_or_edit_post_language__single">
                    <div v-if="language">
                      <router-link v-bind:to="'/' + language_index + '/page/' + translated_page.main_page_id">
                        <font-awesome-icon :icon="['fas', 'pencil-alt']" class="language_icon language_icon__edit"/>
                      </router-link>
                    </div>
                    <div v-else>
                      <router-link v-bind:to="'/' + language_index + '/page/' + translated_page.main_page_id">
                        <font-awesome-icon :icon="['fas', 'plus']" class="language_icon language_icon__add"/>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination" v-if="lastPagePagination !== 1">
          <ul>
            <li v-for="(page, index) in lastPagePagination" :key="index">
              <router-link v-if="index === 0" to="pages">
                {{ page }}
              </router-link>
              <router-link v-else v-bind:to='"pages?page=" + page'>
                {{ page }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="notificationMessage"
      width="500"
    >
      <v-card>
        <v-card-text>
          <Notification v-if="notification.message" :message="notification.message" :type="notification.type"
                  :show="true"></Notification>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { getAllPages, getAllLanguages } from '@/services/page.js'
import { softDeletePostPage } from '@/services/trash'
import Notification from '@/components/Notification'

export default {
  name: 'Pages',
  components: {
    Notification
  },
  data () {
    return {
      notificationMessage: false,
      lang: this.$route.params.lang,
      languages: [],
      allLangs: [],
      notification: {
        message: 'Page is succesfully deleted',
        type: 'success'
      },
      frontPageID: '',
      lastPagePagination: '',
      translatedPosts: [],
      ingArr: [],
      pages: []
    }
  },
  methods: {
    deletePage (type, lang, id) {
      softDeletePostPage(type, lang, id)
    }
  },
  mounted () {
    this.$root.isLoading = true
    getAllLanguages().then(languages => {
      this.allLangs = languages
      getAllPages(this.lang).then(pagesData => {
        var pages = pagesData.data.data
        this.lastPagePagination = pagesData.data.last_page
        pages.map(page => {
          const translatedOn = page.translated_page.map(translation => {
            return translation.lang
          })
          page.languages = {}
          Object.values(languages).map(lang => {
            page.languages[lang] = translatedOn.includes(lang)
          })
          return page
        })
        this.pages = pages
        this.$root.isLoading = false
      })
    })
    this.frontPageID = process.env.VUE_APP_FRONT_PAGE_ID
  },
  watch: {
    $route (to) {
      this.$root.isLoading = true
      getAllLanguages().then(languages => {
        this.allLangs = languages
        getAllPages(to.params.lang, to.query.page).then(pagesData => {
          var pages = pagesData.data.data
          this.lastPagePagination = pagesData.data.last_page
          pages.map(page => {
            const translatedOn = page.translated_page.map(translation => {
              return translation.lang
            })
            page.languages = {}
            Object.values(languages).map(lang => {
              page.languages[lang] = translatedOn.includes(lang)
            })
            return page
          })
          this.pages = pages
          this.$root.isLoading = false
        })
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.post_form_title {
  border-radius: 10px;
  background-color: #42b983;
  color: white;
  padding: 5px 25px;
}

.single_post_row {
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #f2f2f2;
}

.posts_list__title {
  font-size: 20px;
  font-weight: normal;
}

.post_form_title__columns {
  padding: 12px;
}

.post_form_title__columns p {
  font-size: 20px;
}

p {
  margin-bottom: 0;
}

.languages a {
  padding: 5px 15px;
  display: inline-block;
  border-radius: 5px;
  color: #42b983;
  margin-bottom: 15px;
  text-decoration: none;
  transition: all .3s ease;
}

.languages .router-link-exact-active.router-link-active {
  background-color: #42b983;
  color: #fff;
}

.posts_container {
  background-color: white;
  padding: 30px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.languages__title {
  display: inline-block;
  margin-right: 10px;
}

.posts_list {
  margin-top: -10px;
  padding: 20px;
  border: 2px solid #42b983;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.row.single_post_row {
  margin: 0;
}

.edit_link {
  display: flex;
  align-items: center;
  align-content: center;
}

.edit_link__div {
  display: flex;
  align-content: center;
  align-items: center;
  position: relative;
}

.edit_link__div:before {
  content: "|";
  color: #42b983;
  margin: 0 5px;
}

.front_page_notification {
  font-size: 14px;
  color: #9c27b0;
  font-weight: bold;
}

.edit_link__div:first-child:before {
  content: "";
  margin: 0;
}

.edit_link a {
  text-decoration: none;
  font-size: 14px;
  color: #42b983;
}

.languages__flag img {
  margin: 0 3px;
  max-width: 30px;
}

.add_or_edit_post_language {
  display: flex;
  justify-content: space-between;
}

.languages__flag {
  display: flex;
  justify-content: space-between;
}

.add_or_edit_post_language__single {
  max-width: 30px;
  margin-top: 10px;
  text-align: center;
  min-width: 30px;
}

.language_icon {
  font-size: 14px;
}

.language_icon.language_icon__add {
  color: #42b983;
}

.language_icon.language_icon__edit {
  color: #9c27b0;
}

.v-dialog > .v-card > .v-card__text {
  padding: 25px;
}

.alert_notification.success {
  margin-bottom: 0;
}
</style>
