<template>
  <div class="menu-items">
    <div class="row">
      <div class="col-md-6">
        <h3 class="menu-items__h3">Menu items</h3>
        <v-expansion-panels>
          <draggable :list="menuItems" class="draggable_content" handle=".handle">
          <v-expansion-panel
            v-for="(element, idx) in menuItems"
            :key="idx"
            @click="openItemPanel(element)"
          >
            <v-expansion-panel-header>
              <span class="handle dragg_icon">
              <font-awesome-icon :icon="['fas', 'bars']" class="cursor_dragg_drop" />
            </span>
              <span class="item_title">{{ element.name }} <small class="item_type"> {{ element.type }} </small> </span>
              <span class="remove_item">
                <font-awesome-icon :icon="['fas', 'times']" class="cursor_pointer" @click="removeAt(element,idx)" />
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <validation-provider
                v-slot="{ errors }"
                name="Title"
                rules="required|max:255"
              >
                <v-text-field
                  label="Title"
                  v-model="itemProperties.itemName"
                  :counter="255"
                  :error-messages="errors"
                  filled
                  required
                  :value="itemProperties.itemName"
                ></v-text-field>
              </validation-provider>
              <v-text-field label="Title color" v-model="itemProperties.itemColor" hide-details class="ma-0 pa-0" solo>
                <template v-slot:append>
                  <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyle" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="itemProperties.itemColor" mode="hexa" flat />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
              <br>
              <v-text-field label="Background color" v-model="itemProperties.bgColor" hide-details class="ma-0 pa-0" solo>
                <template v-slot:append>
                  <v-menu v-model="menu2" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyle2" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="itemProperties.bgColor" mode="rgba" />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
              <div class="icon_picker_content">
                <vue-awesome-icon-picker :icon-preview="false" @selected="onIconSelected" class="icon_picker" />
                <div class="show_icon_content">
                  <font-awesome-icon v-if="itemProperties.itemIcon" :icon="['fa', itemProperties.itemIcon]" class="show_icon" />
                </div>
              </div>
              <div v-if="element.type !== '' & element.type === 'external'">
                <validation-provider
                  v-slot="{ errors }"
                  name="Url"
                  rules="required"
                >
                  <v-text-field
                    label="Url"
                    v-model="element.item_properties.url"
                    :error-messages="errors"
                    filled
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <v-btn
                :loading="loadingUpdate"
                class="ma-2"
                depressed
                color="primary"
                v-on:click="updateItem(idx)"
              >
                Update
              </v-btn>
              <span v-if="checkUpdateIcon"><font-awesome-icon :icon="['fas', 'check']" class="icon_success" /></span>
            </v-expansion-panel-content>
          </v-expansion-panel>
          </draggable>
        </v-expansion-panels>
        <v-btn
          :loading="loading"
          class="button_style_1 save_menu"
          v-on:click="submit"
        >
          Save
        </v-btn>
        <span v-if="checkValidateIcon"><font-awesome-icon :icon="['fas', 'check']" class="icon_success" /></span>
      </div>
      <div class="col-md-6">
        <h3 class="menu-items__h3">Add new item</h3>
        <v-select
          @change="selectItemType"
          :items="itemType"
          item-text="name"
          item-value="slug"
          label="Select Item Type"
          persistent-hint
          return-object
          solo
        ></v-select>
        <div v-if="typeItems !== ''">
          <div v-if="typeItems === 'external'">
            <validation-provider
              v-slot="{ errors }"
              name="Title"
              rules="required|max:255"
            >
              <v-text-field
                label="Title"
                v-model="externalLink.name"
                :counter="255"
                :error-messages="errors"
                filled
                required
              ></v-text-field>
            </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Url"
                rules="required"
              >
                <v-text-field
                  label="Url"
                  v-model="externalLink.url"
                  :error-messages="errors"
                  filled
                  required
                ></v-text-field>
            </validation-provider>
            <v-btn
              @click="add(externalLink)"
              class="button_style_1"
            >Add
            </v-btn>
          </div>
          <div v-else>
            <div class="search_form">
              <font-awesome-icon :icon="['fas', 'search']" class="search_icon" />
              <input type="text" v-debounce:1s="searchDelay" placeholder="Search">
            </div>
          </div>
        </div>
        <ul class="item_list">
          <li v-for="(item, index) in searchItem" :key="index" class="item_list__li">
            <div @click="add(item)" class="item_list__li__div">
            <font-awesome-icon :icon="['fas', 'plus']" class="add_color" />
              <p>{{ item.name }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import { getAllMenuItem, searchItem } from '@/services/menu'
import draggable from 'vuedraggable'
import { getDirective } from 'vue-debounce'
import myApi from '@/services/myApi'
import { extend, ValidationProvider } from 'vee-validate'
import { max, required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

export default {
  name: 'menu-items',
  components: {
    draggable,
    ValidationProvider
  },
  data () {
    return {
      menuItems: [],
      lang: this.$route.params.lang,
      id: this.$route.params.id,
      search: '',
      dragging: true,
      typeItems: '',
      searchItem: [],
      itemType: [
        { name: 'Category', slug: 'category' },
        { name: 'Post', slug: 'posts' },
        { name: 'Page', slug: 'pages' },
        { name: 'External link', slug: 'external' }
      ],
      deletedItems: [],
      itemProperties: {
        itemColor: '',
        itemName: '',
        itemIcon: '',
        bgColor: null
      },
      menu: false,
      menu2: false,
      externalLink: {
        name: '',
        relatedId: null,
        url: ''
      },
      loading: false,
      checkValidateIcon: false,
      loadingUpdate: false,
      checkUpdateIcon: false
    }
  },
  directives: {
    debounce: getDirective()
  },
  mounted () {
    this.$root.isLoading = true
    getAllMenuItem(this.lang, this.id).then(menuItems => {
      this.menuItems = menuItems
      this.$root.isLoading = false
    })
  },
  watch: {
    $route (to) {
      getAllMenuItem(to.params.lang, to.params.id).then(menuItems => {
        this.menuItems = menuItems
      })
      this.lang = to.params.lang
      this.id = to.params.id
    },
    checkTypeSearch () {
      this.$root.isLoading = true
      this.searchItem = []
      if (this.typeItems !== 'external') {
        searchItem(this.lang, this.typeItems, this.search).then(items => {
          items.map((item) => {
            if (this.typeItems === 'posts') {
              this.searchItem.push({ name: item.post[0].title, relatedId: item.post[0].main_post_id })
            } else if (this.typeItems === 'category') {
              this.searchItem.push({ name: item.category[0].name, relatedId: item.category[0].main_category_id })
            } else if (this.typeItems === 'pages') {
              this.searchItem.push({ name: item.page[0].title, relatedId: item.page[0].main_page_id })
            }
          })
        })
      }
      this.$root.isLoading = false
    }
  },
  computed: {
    checkTypeSearch: function () {
      return [this.search, this.typeItems, this.lang]
    },
    swatchStyle () {
      const { menu } = this
      return {
        backgroundColor: this.itemProperties.itemColor,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
        border: '1px solid'
      }
    },
    swatchStyle2 () {
      const { menu2 } = this
      return {
        backgroundColor: this.itemProperties.bgColor,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu2 ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
        border: '1px solid'
      }
    }
  },
  methods: {
    removeAt (element, idx) {
      if (element.id) {
        this.deletedItems.push(element.id)
      }
      this.menuItems.splice(idx, 1)
    },
    add: function (item) {
      if (this.typeItems !== 'external') {
        this.menuItems.push({ name: item.name, type: this.typeItems, related_id: item.relatedId, action: 'new_item', item_properties: { color: '#000000', bgcolor: '#ffffff', icon: '', url: null } })
      } else {
        this.menuItems.push({
          name: this.externalLink.name,
          type: this.typeItems,
          related_id: null,
          action: 'new_item',
          item_properties: { color: '#000000', bgcolor: '#ffffff', icon: '', url: this.externalLink.url }
        })
      }
    },
    selectItemType (itemType) {
      this.typeItems = itemType.slug
    },
    searchDelay (val) {
      this.search = val
    },
    submit () {
      this.loading = true
      myApi.post(`api/${this.lang}/menu/${this.id}`, {
        lang: this.lang,
        menu_items: this.menuItems,
        deleted_items: this.deletedItems,
        menu_id: this.id,
        type: this.typeItems
      }).then(() => {
        this.menuItems.map((item) => {
          if ('action' in item) {
            item.action = ''
          }
          this.loading = false
          this.checkValidateIcon = true
          setTimeout(() => (this.checkValidateIcon = false), 1000)
        })
        this.checkValidateIcon = true
        this.loading = false
      })
    },
    openItemPanel (element) {
      this.itemProperties.itemName = element.name
      this.itemProperties.itemColor = element.item_properties.color
      this.itemProperties.itemIcon = element.item_properties.icon
      this.itemProperties.bgColor = element.item_properties.bg_color
    },
    updateItem (idx) {
      this.loadingUpdate = true
      const itemObject = this.menuItems[idx]
      itemObject.name = this.itemProperties.itemName
      itemObject.item_properties.color = this.itemProperties.itemColor
      itemObject.item_properties.bg_color = this.itemProperties.bgColor
      itemObject.item_properties.icon = this.itemProperties.itemIcon
      this.loadingUpdate = false
      this.checkUpdateIcon = true
      setTimeout(() => (this.checkUpdateIcon = false), 1000)
    },
    onIconSelected (icon) {
      this.itemProperties.itemIcon = icon.name
    }
  }
}

</script>

<style scoped>
.icon_success{
  margin-left: 20px;
  color: #42b983;
}
.item_type{
  color: red;
  text-transform: uppercase;
}

.button_style_1.save_menu {
  margin-top: 30px;
}

.search_form input {
  border-bottom: 1px solid gray;
  margin-left: 5px;
  display: block;
  outline: none;
  padding: 10px 25px;
}

.search_form .search_icon {
  color: #42b983;
  position: absolute;
  left: 5px;
  font-size: 18px;
}

.search_form {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.icon_picker_content {
  padding: 20px 0;
}
.show_icon_content {
  float: right;
}
.icon_picker {
  display: inline-block;
}
.show_icon {
  font-size: 40px;
}

.item_list__li__div {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  padding: 5px 0;
}

.item_list{
  padding: 0;
  list-style-type: none;
}

.item_list__li p {
  margin-left: 15px;
  font-size: 17px;
}

.item_list__li {
  background-color: white;
  padding: 10px;
  margin-bottom: 5px;
}

.item_title {
  flex: 0 1 80% !important;
}

.draggable_content{
  width: 100%;
}

.cursor_dragg_drop{
  cursor: move;
}

.cursor_pointer {
  margin-left: auto;
  margin-right: 5px;
  color: red;
  cursor: pointer;
}

.list-group-item {
  list-style: none;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

.list-group-item {
  display: flex;
  align-items: center;
}

.single_post_box {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}

.buttons_section {
  margin-bottom: 10px;
  text-align: right;
}

.add_color {
  color: #42b983;
}

.handle {
  margin-right: 10px;
}

.text {
  margin: 0 !important;
}

.post_form_title__columns p{
  font-size: 20px;
}

p {
  margin-bottom: 0;
}
.languages a {
  padding: 5px 15px;
  display: inline-block;
  border-radius: 5px;
  color: #42b983;
  text-decoration: none;
  transition: all .3s ease;
}
.languages .router-link-exact-active.router-link-active {
  background-color: #42b983;
  color: #fff;
}

.edit_link_p a{
  text-decoration: none;
  font-size: 14px;
  color: #42b983;
}

.pagination ul {
  list-style: none;
  padding-left: 0;
}

.pagination ul li {
  display: inline-block;
}

.pagination ul li p {
  padding: 3px 15px;
}

.pagination ul li p.active {
  background-color: #42b983;
  padding: 3px 15px;
  border-radius: 5px;
  color: white;
}

/*draggable*/
.button {
  margin-top: 35px;
}
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}
input {
  display: inline-block;
  width: 50%;
}
.text {
  margin: 20px;
}

.menu-items__h3 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: normal;
}

</style>
