import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    user: {},
    isLoggedIn: false
  },
  getters: {
    getUserData: state => state.user,
    getUserToken: state => state.user.access_token
  }
})
