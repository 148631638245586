<template>
  <div>

    <v-btn v-if="showButton" @click="showModal" class="button_style_1">Select image</v-btn>

    <v-dialog
      v-model="dialog"
      width="900"
    >

      <v-card>

        <v-card-text class="media__box__content">
          <header class="tab-header media_tab_header">
            <button @click="tab = 1;" :class="{ active: tab == 1 }" type="button">Media</button>
            <button @click="tab = 0;" :class="{ active: tab == 0 }" type="button">
              Upload (Drag 'n' Drop)
            </button>
          </header>

          <div v-if="tab == 1">
            <div v-if="mediaType === 'image' || mediaType === 'gallery'" class="d-flex flex-row flex-wrap media_images">
              <v-img
                v-for="(img, i) in images"
                :key="'img-' + i"
                :alt="img.alt"
                :src="img.src"
                class="ma-2 selectable"
                :class="{ selected: selectedImage && img.id === selectedImage.id}"
                max-height="100"
                max-width="100"
                @click="selectImage(img)"
              />
            </div>

            <div v-if="mediaType === 'document'" class="d-flex flex-row flex-wrap media_images">
              <div
                v-for="(doc, i) in documents"
                :key="'doc-' + i"
                class="ma-2 selectable"
                :class="{ selected: selectedDocument && doc.id === selectedDocument.id}"
                @click="selectDocument({src: doc.src, name: doc.alt, type: 'document'})">
                {{ doc.alt }}
              </div>
            </div>
          </div>
          <div v-if="tab == 0">
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              @vdropzone-complete="vfileUploaded"
              :options="dropzoneOptions"
            >
            </vue-dropzone>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="modal__footer">

          <v-btn
            v-if="mediaType === 'image'"
            @click="insertImage"
            class="button_style_1"
            :title="validImage ? '' : 'Image URL needs to be valid'"
            :disabled="!selectedImage"
            type="button"
          >
            Add Image
          </v-btn>

          <v-btn
            v-else-if="mediaType==='document'"
            @click="insertDocument"
            class="button_style_1"
            :title="validImage ? '' : 'Document URL needs to be valid'"
            :disabled="!selectedDocument"
            type="button"
          >
            Add Document
          </v-btn>

          <v-btn
            v-else
            @click="insertGallery"
            class="button_style_1"
            :title="validImage ? '' : 'Image URL needs to be valid'"
            :disabled="!selectedImage"
            type="button"
          >
            Add Gallery
          </v-btn>
          <v-btn
            class="button_style_2"
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
/**
 * Example of a custom Image selector
 * Key is to emit a select-file event when a file needs to be added
 */
import { VImg } from 'vuetify/lib'
import myApi from '@/services/myApi'
import vue2Dropzone from 'vue2-dropzone'
import { getAllImages, getAllDocuments } from '@/services/media'

export default {
  name: 'FileSelector',
  components: {
    vueDropzone: vue2Dropzone,
    VImg
  },
  props: {
    fileSelect: {
      type: Function
    },
    uploadGallery: {
      type: Function
    },
    showButton: {
      type: Boolean,
      default: true
    },
    mediaType: {
      type: String,
      default: 'image'
    }
  },
  data () {
    // Some public domain images from wikimedia.
    return {
      dialog: false,
      selectedImage: null,
      selectedDocument: null,
      images: [],
      gallery: [],
      documents: [],
      imageSrc: '',
      command: null,
      show: false,
      tab: 1,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        addRemoveLinks: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    }
  },
  computed: {
    validImage () {
      return (
        this.imageSrc.match(/unsplash/) !== null ||
        this.imageSrc.match(/\.(jpeg|jpg|gif|png)$/) != null
      )
    }
  },
  methods: {
    selectImage (img) {
      this.selectedImage = img
      this.gallery.push(img)
    },

    selectDocument (document) {
      this.selectedDocument = document

      // this.$emit('select-file', img)
    },

    showModal () {
      this.dialog = true
      this.$emit('openModal')
    },

    vfileUploaded (file) {
      const formData = new FormData()
      formData.append('file', file)
      myApi.post('/api/media', formData)
    },

    insertImage () {
      this.fileSelect(this.selectedImage)
      this.closeModal()
    },

    insertDocument () {
      this.fileSelect(this.selectedDocument)
      this.closeModal()
    },

    insertGallery () {
      this.uploadGallery(this.gallery)
      this.closeModal()
    },

    closeModal () {
      this.show = false
      this.imageSrc = ''
      this.tab = 1
    }
  },

  mounted () {
    getAllDocuments().then(result => {
      this.documents = result.data.data.map(singleDocument => {
        singleDocument.src = 'https://aktiv.w3-lab.net/' + singleDocument.slug
        singleDocument.media = 'document'
        return singleDocument
      })
    })
    getAllImages().then(result => {
      this.images = result.data.data.map(singleImage => {
        singleImage.src = 'https://aktiv.w3-lab.net/' + singleImage.properties.thumbnail
        singleImage.media = 'image' // TODO: Check if we have it in the response
        return singleImage
      })
    })
  }
}
</script>

<style scoped>
.v-image.selected {
  border: 1px solid #42b983;
}

.v-image {
  position: relative;
  border: 1px solid transparent;
}

.v-image.selected:before {
  content: '';
  background-color: rgba(0, 0, 0, .4);
  position: absolute;
  height: 100%;
  width: 100%;
}

.v-image.selected:after {
  content: '\2714';
  font-size: 24px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: #42b983;
}

.selectable {
  cursor: pointer;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  width: 90%;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
}

.modal-footer {
  margin-top: 10px;
}

label {
  display: block;
  margin: 0.25em 0;
}

button {
  font-family: inherit;
  font-size: 100%;
  padding: 0.5em 1em;
  color: white;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid #999;
  border: transparent;
  background-color: #e6e6e6;
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
}

button.danger {
  background: rgb(202, 60, 60);
}

button.success {
  background: rgb(28, 184, 65);
}

button:disabled {
  opacity: 0.3;
}

button + button {
  margin-left: 10px;
}

.tab-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #222;
}

.tab-header button {
  color: #222;
  background: none;
  border: 0;
  flex: 1;
  padding: 5px 10px;
  cursor: pointer;
}

.tab-header button.active {
  background-color: #222;
  color: #fff;
}

.tab-header.media_tab_header button {
  padding: 20px 0;
  font-size: 20px;
}

.tab-header.media_tab_header button.active {
  background-color: #42b983;
}

.tab-header.media_tab_header {
  border-bottom: 1px solid #42b983;
}

.v-dialog .v-card .v-card__text.media__box__content {
  padding: 0;
}

.media_images {
  padding: 30px;
}

.v-card__actions.modal__footer {
  padding: 20px;
  justify-content: center;
}
</style>
