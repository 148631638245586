import myApi from '@/services/myApi'

export function getAllPages (lang, page) {
  return myApi.get('api/' + lang + '/pages?page=' + page)
    .then((res) => {
      return res
    })
}

export function getSinglePage (lang, id) {
  return myApi.get('api/' + lang + '/pages/' + id)
    .then((page) => {
      return page.data
    })
}

export function getAllLanguages () {
  return myApi.get('/api/all_langs')
    .then(lang => {
      return lang.data
    })
}

export function getAllLanguagesPages () {
  return myApi.get('api/pages')
    .then(pages => {
      return pages.data.data
    })
}
