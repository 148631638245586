<template>
  <div class="hello">
    <div v-if="multiple" class="preview-single">
      <img v-if="images[0]" :src="images[0].src" alt=""/>
    </div>
    <div v-else>
      <div v-if="mutableImageSlug">
        <img :src="'https://aktiv.w3-lab.net/' + mutableImageSlug" alt="">
      </div>
      <div v-else>
        <img :src="'https://aktiv.w3-lab.net/' + featuredImageSlug" alt="">
      </div>
    </div>

    <file-selector :fileSelect="fileSelect"/>
  </div>
</template>

<script>
import FileSelector from '@/components/Tiptap/FileSelector'

export default {
  name: 'ImageSelectorInput',
  components: { FileSelector },
  data () {
    return {
      images: [],
      mutableImageSlug: ''
    }
  },
  props: {
    multiple: Boolean,
    featuredImageSlug: String
  },
  methods: {
    fileSelect (file) {
      this.images = [file]
      this.mutableImageSlug = file.slug
      this.$emit('input', file.id)
    }
  },
  mounted () {
    this.mutableImageSlug = this.featuredImageSlug
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
