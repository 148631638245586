<template>
  <div class="register">
    <v-app-bar app>
      Register
    </v-app-bar>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <form @submit.prevent="submit">
        <validation-provider
          v-slot="{ errors }"
          name="Name"
          rules="required|max:30"
        >
          <v-text-field
            v-model="userData.name"
            :counter="255"
            :error-messages="errors"
            label="Name"
            required
          ></v-text-field>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="email"
          rules="required|email"
        >
          <v-text-field
            v-model="userData.email"
            :error-messages="errors"
            label="E-mail"
            required
          ></v-text-field>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="password"
          rules="required"
        >
          <v-text-field
            v-model="userData.password"
            :error-messages="errors"
            label="Password"
            required
          ></v-text-field>
        </validation-provider>
        <div class="user_role">
          <validation-provider
            v-slot="{ errors }"
            name="role"
            rules="required"
          >
            <v-select
              v-model="role"
              :items="roles"
              :error-messages="errors"
              item-text="name"
              item-value="id"
              label="Select role"
              persistent-hint
              return-object
              single-line
            ></v-select>
          </validation-provider>
        </div>
        <v-btn
          class="mr-4"
          type="submit"
          :disabled="invalid"
        >
          submit
        </v-btn>
        <div class="register_error">
          <v-alert v-if="error.email !== '' || error.name !== ''" type="error">
            <p v-if="error.email !== ''">{{error.email}}</p>
            <p v-if="error.name !== ''">{{error.name}}</p>
          </v-alert>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import myApi from '@/services/myApi'
import { getAllRoles } from '@/services/user'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})'
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}'
})

extend('email', {
  ...email,
  message: 'Email must be valid'
})

export default {
  name: 'Register',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    userData: {
      name: '',
      email: '',
      password: ''
    },
    role: {},
    roles: [],
    error: {
      name: '',
      email: ''
    }
  }),
  mounted () {
    this.$root.isLoading = true
    getAllRoles().then(roles => {
      this.role = roles[0]
      this.roles = roles
    })
    this.$root.isLoading = false
  },
  methods: {
    submit () {
      myApi.post('api/auth/register', {
        name: this.userData.name,
        email: this.userData.email,
        password: this.userData.password,
        role_id: this.role.id
      }).then((response) => {
        if (response.status === 200) {
          this.$router.push({ path: '/users' })
        }
      }
      ).catch((error) => {
        this.error.name = ''
        this.error.email = ''
        const catchError = error.response.data.errors
        if ('name' in catchError) {
          this.error.name = catchError.name[0]
        }
        if ('email' in catchError) {
          this.error.email = catchError.email[0]
        }
      })
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$primaryColor: red;

h1 {
  color: $primaryColor
}
p {
  margin: 0;
}
.register_error {
  margin-top: 20px;
}
</style>
