<template>
  <div class="login">
    <div class="container">
      <div class="row">
        <div class="col-md-4">

        </div>
        <div class="col-md-4 login-form">
          <h2 class="login-form__h2">Welcome to CMS</h2>
          <p class="login-form__p">Have an account?</p>
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <form @submit.prevent="submit">
              <validation-provider
                v-slot="{ errors }"
                name="email"
                rules="required|email"
              >
                <v-text-field
                  v-model="userData.email"
                  :error-messages="errors"
                  required
                  :class="{ 'my-text-style': someBool }"
                  placeholder="Email"
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="password"
                rules="required"
              >
                <v-text-field
                  v-model="userData.password"
                  :error-messages="errors"
                  required
                  :class="{ 'my-text-style': someBool }"
                  placeholder="password"
                  type="password"
                ></v-text-field>
              </validation-provider>
              <div class="login-form__buttons">
                <v-btn
                  class="mr-4 login-form__submit-button"
                  type="submit"
                  :disabled="invalid"
                >
                  submit
                </v-btn>
                </div>
            </form>
            <div class="login-form__footer">
              <div class="forgot_password">
                <a href="#" class="forgot_password__a">Forgot password?</a>
              </div>
            </div>
          </validation-observer>
        </div>
         <div class="col-md-4">

         </div>
      </div>
    </div>
  </div>

</template>

<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import myApi from '@/services/myApi'
import router from '@/router'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})'
})

extend('required', {
  ...required,
  message: '{_field_} je obavezno.'
})

extend('max', {
  ...max,
  message: '{_field_} ne bi trebalo da ima vise od {length} karaktera.'
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}'
})

extend('email', {
  ...email,
  message: 'Email mora biti validan'
})

export default {
  name: 'Login',
  props: {
    msg: String
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    userData: {
      email: '',
      password: ''
    },
    someBool: true
  }),

  methods: {
    submit () {
      this.$refs.observer.validate()
      myApi.post('api/auth/login', {
        email: this.userData.email,
        password: this.userData.password
      }).then((res) => {
        var authToken = res.data.access_token
        window.$cookies.set('bearerToken', authToken)
        this.$store.state.user = res.data
        this.$store.state.isLoggedIn = true
        router.push('/rs/posts')
      })
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.login {
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/login-background-image.jpg");
}

.login-form{
  max-width: 450px;
  margin: 0 auto;
  padding: 40px;
  background-color: rgba(0,0,0,0.6);
  border-radius: 5px;
}

.login-form__h2 {
  color: white;
  font-size: 40px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 500;
}

.login-form__p {
  margin-bottom: 30px;
  font-size: 22px;
  color: white;
  text-align: center;
}

.my-text-style >>> .v-text-field__slot input {
  color: #fff!important;
  border: 1px solid transparent;
  background: rgba(255,255,255,.08);
  border-radius: 40px;
  padding: 20px 20px;
  font-weight: 500;
  transition: .3s;
}

.my-text-style >>> .v-text-field__slot input::placeholder {
  color: rgba(255,255,255,0.8);
}

.my-text-style.v-input--is-focused >>> .v-text-field__slot input {
  color: #fff!important;
  font-weight: 500;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.4);
  background: rgba(255,255,255,.08);
  border-radius: 40px;
  padding: 20px 20px;
  transition: .3s;
}

.my-text-style >>> .v-input__slot:before,
.my-text-style >>> .v-input__slot:after {
  display: none;
}

.my-text-style >>> .v-messages__message {
  color: white;
  margin-left: 10px;
}

.login-form__buttons {
  margin-top: 30px;
  padding: 15px 30px;
}

.login-form__buttons .login-form__submit-button.v-btn {
  height: 50px;
  background-color: #42b983;
  color: white;
  font-weight: bold;
}

.login-form__buttons .login-form__submit-button.v-btn .v-btn__content {
  color: rgba(255,255,255,0.3);
}

.login-form__submit-button {
  width: 100%;
  border-radius: 40px;
}

.login-form__footer {
  margin-top: 30px;
}

.forgot_password__a {
  color: white;
  float: right;
  text-decoration: none;
}

</style>
