<template>
  <Login v-if="Object.keys(userDataData).length === 0 && !isLoggedIn"></Login>
    <v-app v-else>
    <v-navigation-drawer app>
      <Navbar></Navbar>

    </v-navigation-drawer>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <div class="vld-parent">
          <loading :active.sync="$root.isLoading"
                   :is-full-page="true"
                   loader="bars"
                   color="#42b983"
          >
          </loading>
        </div>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import Navbar from '@/components/Navbar'
import Login from '@/components/User/Login'
import isLoggedIn from '@/Helpers/isLoggedIn'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'App',
  components: {
    Navbar,
    Login,
    Loading
  },
  data: () => ({
    isLoading: false,
    fullPage: true,
    isLoggedIn: isLoggedIn(),
    userDataData: {
    }
  }),
  computed: {
    isUserState () {
      return this.$store.state.user
    }
  },
  watch: {
    isUserState (userData) {
      this.userDataData = userData
    }
  }
}
</script>

<style>
  img {
    max-width: 100%;
    height: auto;
  }
.rip-popup-component {
  max-width: calc(100% - 256px) !important;
  right: 0 !important;
  left: auto !important;
  top: 60px !important;
  height: calc(100% - 60px) !important;
  overflow: hidden;
}
  .theme--light.v-navigation-drawer {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./assets/sidebar-cms.jpg");
    background-position: center center;
    background-color: #42b983;
  }
  .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    background-color: #42b983;
    color: white;
    font-weight: normal;
  }
  .container.container--fluid {
    padding: 30px;
  }

  .v-main__wrap {
    background-color: #f2f2f2;
  }

  .ck-editor__editable_inline {
    min-height: 610px;
  }

  .navigation {
    display: flex;
    overflow: hidden;
  }

  .navigation .navigation__item a {
    display: inline-block;
    padding: 10px 20px;
    padding-bottom: 15px;
    background-color: white;
    border: 2px solid white;
    margin: 0 15px -10px 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #42b983;
    font-weight: bold;
    text-decoration: none;
  }

  .navigation__item a.router-link-active {
    background-color: #42b983;
    border-color: #42b983;
    color: white;
  }

  .container.container--fluid {
    position: relative;
  }

  .modal {
    z-index: 99999;
  }

  .ProseMirror {
    padding: 15px;
  }

  .button_style_1.theme--light.v-btn.v-btn--has-bg {
    background-color: #42b983;
    color: #fff;
    padding: 5px 25px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
  }

  .button_style_2.theme--light.v-btn.v-btn--has-bg {
    padding: 5px 25px;
    background-color: #9c27b0;
    border-radius: 5px;
    color: white;
    text-transform: uppercase;
    text-align: center;
  }

  .vue-dropzone.dropzone {
    height: 200px;
    margin: 15px;
    border: 1px solid #42b983;
    border-radius: 5px;
    padding: 10px;
  }
  .v-application .tip_tap_editor p {
    padding: 0;
    margin-bottom: 10px;
  }

  .navigation_menu {
    text-transform: uppercase;
  }

  .pagination {
  padding-top: 30px;
  margin: 0 auto;
  text-align: left;
}

.pagination ul {
  list-style: none;
  padding-left: 0;
}

.pagination ul li {
  display: inline-block;
}

.pagination ul li a {
  padding: 3px 15px;
  color: black;
  text-decoration: none;
}

.pagination ul li a.router-link-exact-active {
  background-color: #42b983;
  padding: 3px 15px;
  border-radius: 5px;
  color: white;
}

.navbar_icon {
  margin-right: 15px;
}

</style>
