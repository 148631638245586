<template>
  <div :class="type" class="alert_notification" @input = showAndHideNotification(show)>
    <div v-if="type === 'success'" class="notification__icon">
      <font-awesome-icon :icon="['fas', 'check-circle']" class="navbar_icon"/>
    </div>
    <div v-else class="notification__icon">
      <font-awesome-icon :icon="['fas', 'times-circle']" class="navbar_icon"/>
    </div>
    {{ message }}
  </div>
</template>

<script>

export default {
  name: 'Notification',
  data () {
    return {
    }
  },
  props: {
    message: String,
    type: String,
    show: Boolean
  },
  methods: {
    showAndHideNotification (show) {
      if (show) {
      } else {
      }
    }
  }
}

</script>

<style>

.alert_notification {
  display: flex;
  align-content: center;
  align-items: center;
}

.alert_notification.success {
  z-index: 999;
  color: white!important;
  border-color: #4caf50;
  padding: 20px 40px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.alert_notification.error {
  z-index: 999;
  color: white!important;
  border-color: #4caf50;
  padding: 20px 40px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.notification__icon {
  display: inline-block;
  margin-right: 15px;
}

.notification__icon svg{
  font-size: 25px;
}

</style>
