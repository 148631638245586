import myApi from '@/services/myApi'

export function getAllPosts (lang, page) {
  return myApi.get('api/' + lang + '/posts?page=' + page)
    .then((res) => {
      return res
    })
}

export function getSinglePost (lang, id) {
  return myApi.get('api/' + lang + '/posts/' + id)
    .then((post) => {
      return post
    })
}

export function getAllLanguages () {
  return myApi.get('/api/all_langs')
    .then(lang => {
      return lang.data
    })
}

export function getAllLanguagesPosts () {
  return myApi.get('api/posts')
    .then(posts => {
      return posts.data.data
    })
}
