import myApi from '@/services/myApi'

export function getAllUsers () {
  return myApi.get('api/auth/users')
    .then((res) => {
      return res.data
    })
}
export function getUser (id) {
  return myApi.get(`api/auth/users/${id}`)
    .then((res) => {
      return res.data
    })
}

export function getAllRoles () {
  return myApi.get('api/all_roles')
    .then((res) => {
      return res.data
    })
}
