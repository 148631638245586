<template>
  <div class="posts">
    <div class="navigation">
      <div class="navigation__item" v-if="type==='posts'">
        <router-link to="/sr/posts">
          Posts
        </router-link>
      </div>
      <div class="navigation__item" v-else>
        <router-link to="/sr/pages">
          Pages
        </router-link>
      </div>
      <div class="navigation__item" v-if="type==='posts'">
        <router-link to="/sr/categories">
          Categories
        </router-link>
      </div>
      <div class="navigation__item" v-if="type==='posts'">
        <router-link to="/sr/posts/trash">
          Trash
        </router-link>
      </div>
      <div class="navigation__item" v-else>
        <router-link to="/sr/pages/trash">
          Trash
        </router-link>
      </div>
    </div>
    <div class="posts_container">
      <v-app-bar app>
        <font-awesome-icon :icon="['fas', 'newspaper']" class="navbar_icon"/>
        Posts
      </v-app-bar>
      <div class="languages">
        <p class="languages__title">Language:</p>
        <div v-if="type==='posts'" style="display: inline">
          <router-link v-for="(lang, index) in allLangs"  :to="'/' + lang + '/posts/trash'" :key="index" class="navigation_menu">
            {{ lang }}
          </router-link>
        </div>
        <div v-else style="display: inline">
          <router-link v-for="(lang, index) in allLangs"  :to="'/' + lang + '/pages/trash'" :key="index" class="navigation_menu">
            {{ lang }}
          </router-link>
        </div>
      </div>
      <div class="container">
        <div class="row post_form_title">
          <div class="col-md-3 post_form_title__columns">
            <p>Title</p>
          </div>
          <div class="col-md-3 post_form_title__columns">
          </div>
          <div class="col-md-2 post_form_title__columns">
          </div>
          <div class="col-md-1 post_form_title__columns">
          </div>
          <div class="col-md-3 post_form_title__columns">
            <p>Deleted at</p>
          </div>
        </div>
      </div>
      <div class="posts_list">
         <div v-for="(item_post, index) in posts" :key="index">
           <div class="row single_post_row">
             <div class="col-md-3">
               <h3 class="posts_list__title">{{ item_post.title }}</h3>
               <p class="edit_link_p" v-if="type==='posts'">
                 <a @click="restore('posts', item_post.lang, item_post.id); restoreNotification('Post is successfully restored', 'success'); notificationMessage='true'">Restore post</a> |
                 <a @click="permanentDelete('posts', item_post.lang, item_post.id); permanentDeleteNotification('Post is permanent deleted', 'success'); notificationMessage='true'">Permanent delete</a>
               </p>
               <p class="edit_link_p" v-else>
                 <a @click="restore('pages', item_post.lang, item_post.main_page_id); restoreNotification('Page is successfully restored', 'success'); notificationMessage='true'">Restore page</a> |
                 <a @click="permanentDelete('pages', item_post.lang, item_post.id); permanentDeleteNotification('Page is permanent deleted', 'success'); notificationMessage='true'">Permanent delete</a>
               </p>
             </div>
             <div class="col-md-3"></div>
             <div class="col-md-2"></div>
             <div class="col-md-1"></div>
             <div class="col-md-3">
               {{ item_post.deleted_at | formatDate }}
             </div>
           </div>
         </div>
       </div>
      <div class="pagination">
        <ul>
          <li v-for="(page, index) in lastPagePagination" :key="index">
            <router-link v-if="index === 0" to="trash">
              {{ page }}
            </router-link>
            <router-link v-else v-bind:to='"trash?page=" + page'>
              {{ page }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <v-dialog
      v-model="notificationMessage"
      width="500"
    >
      <v-card>
        <v-card-text>
          <Notification v-if="notification.message" :message="notification.message" :type="notification.type"
                  :show="true"></Notification>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { getAllTrashPages, getAllTrashPosts, permanentDeletePostPage, restorePostPage } from '@/services/trash'
import Notification from '@/components/Notification'
import { getAllLanguages } from '@/services/getLanguages'

export default {
  name: 'Trash',
  components: {
    Notification
  },
  data () {
    return {
      posts: [],
      languages: [],
      notificationMessage: false,
      categoryRelation: [],
      notification: {
        message: '',
        type: ''
      },
      allLangs: [],
      translatedPosts: [],
      lastPagePagination: '',
      ingArr: [],
      lang: this.$route.params.lang,
      type: this.$route.params.type
    }
  },
  methods: {
    permanentDelete (type, lang, id) {
      permanentDeletePostPage(type, lang, id)
    },
    restore (type, lang, id) {
      restorePostPage(type, lang, id)
    },
    restoreNotification (message, type) {
      this.notification.message = message
      this.notification.type = type
    },
    permanentDeleteNotification (message, type) {
      this.notification.message = message
      this.notification.type = type
    }
  },
  mounted () {
    this.$root.isLoading = true
    if (this.type === 'posts') {
      getAllTrashPosts(this.lang).then(posts => {
        this.lastPagePagination = posts.data.last_page
        console.log(posts)
        this.posts = posts.data.data
        this.$root.isLoading = false
      })
    } else {
      getAllTrashPages(this.lang).then(pages => {
        this.lastPagePagination = pages.data.last_page
        this.posts = pages.data.data
        this.$root.isLoading = false
      })
    }
    getAllLanguages().then((response) => {
      this.allLangs = response
    })
  },
  watch: {
    $route (to) {
      this.$root.isLoading = true
      if (this.type === 'posts') {
        getAllTrashPosts(to.params.lang).then(posts => {
          this.lastPagePagination = posts.data.last_page
          this.posts = posts.data.data
          this.$root.isLoading = false
        })
      } else {
        getAllTrashPages(to.params.lang).then(pages => {
          this.lastPagePagination = pages.data.last_page
          this.posts = pages.data.data
          this.$root.isLoading = false
        })
      }
    }
  }
}

</script>

<style scoped>
  .post_form_title {
    border-radius: 10px;
    background-color: #42b983;
    color: white;
    padding: 5px 25px;
  }

  .single_post_row {
    align-items: center;
    align-content: center;
    border-bottom: 1px solid #f2f2f2;
  }

  .posts_list__title {
    font-size: 20px;
    font-weight: normal;
  }

  .post_form_title__columns {
    padding: 12px;
  }

  .post_form_title__columns p{
    font-size: 20px;
  }

  p {
    margin-bottom: 0;
  }
  .languages a {
    padding: 5px 15px;
    display: inline-block;
    border-radius: 5px;
    color: #42b983;
    margin-bottom: 15px;
    text-decoration: none;
    transition: all .3s ease;
  }
  .languages .router-link-exact-active.router-link-active {
    background-color: #42b983;
    color: #fff;
  }

  .posts_container {
    background-color: white;
    padding: 30px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .languages__title {
    display: inline-block;
    margin-right: 10px;
  }

  .posts_list {
    margin-top: -10px;
    padding: 20px;
    border: 2px solid #42b983;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .row.single_post_row {
    margin: 0;
  }

  .edit_link_p a{
    text-decoration: none;
    font-size: 14px;
    color: #42b983;
  }

  .pagination {
    padding-top: 30px;
    margin: 0 auto;
    text-align: left;
  }

  .v-dialog > .v-card > .v-card__text {
  padding: 25px;
}

.alert_notification.success {
  margin-bottom: 0;
}

  .pagination ul {
    list-style: none;
    padding-left: 0;
  }

  .pagination ul li {
    display: inline-block;
  }

  .pagination ul li p {
    padding: 3px 15px;
  }

  .pagination ul li p.active {
    background-color: #42b983;
    padding: 3px 15px;
    border-radius: 5px;
    color: white;
  }

  .navbar_icon {
    margin-right: 15px;
  }

</style>
