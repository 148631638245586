import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/components/Dashboard'
import Menus from '@/components/Menus'
import MenuItems from '@/components/MenuItems'
import Users from '@/components/Users'
import EditUser from '@/components/EditUser'
import Register from '@/components/User/Register'
import Posts from '@/components/Post/Posts'
import Pages from '@/components/Page/Pages'
import Page from '@/components/Page/Page'
import SinglePost from '@/components/Post/SinglePost'
import NewPost from '@/components/Post/NewPost'
import Category from '@/components/Post/Category'
import Trash from '@/components/Trash'
import NewPage from '@/components/Page/NewPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/:lang/posts',
    name: 'Posts',
    component: Posts
  },
  {
    path: '/:lang/post/:id',
    name: 'SinglePost',
    component: SinglePost
  },
  {
    path: '/add_new/page',
    name: 'NewPage',
    component: NewPage
  },
  {
    path: '/:lang/page/:id',
    name: 'Page',
    component: Page
  },
  {
    path: '/:lang/pages',
    name: 'Pages',
    component: Pages
  },
  {
    path: '/add_new/post',
    name: 'NewPost',
    component: NewPost
  },
  {
    path: '/:lang/categories',
    name: 'Category',
    component: Category
  },
  {
    path: '/:lang/category/:id',
    name: 'Category',
    component: Category
  },
  {
    path: '/:lang/menus',
    name: 'menus',
    component: Menus,
    children: [
      {
        path: ':id',
        name: 'menu-items',
        component: MenuItems
      }
    ]
  },
  {
    path: '/users',
    name: 'users',
    component: Users
  },
  {
    path: '/users/register',
    name: 'register',
    component: Register
  },
  {
    path: '/user/:id',
    name: 'edit-user',
    component: EditUser
  },
  {
    path: '/:lang/:type/trash',
    name: 'Trash',
    component: Trash
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
