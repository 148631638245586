import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import router from '@/router'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import { store } from '@/store'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueAwesomeIconPicker from '@rightbraintechbd/vue-awesome-icon-picker'
import moment from 'moment'

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD MMM YYYY')
  }
})

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('vue-awesome-icon-picker', VueAwesomeIconPicker)

Vue.use(VueCookies)
Vue.use(VueRouter)
Vue.use(CKEditor)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  data: {
    isLoading: false
  },
  render: h => h(App)
}).$mount('#app')
