<template>
  <div class="hello">
    <nav class="container">
      <ul>
        <li>
          <router-link to="/sr/posts"><font-awesome-icon :icon="['fas', 'newspaper']" class="navbar_icon" />Posts<font-awesome-icon :icon="['fas', 'caret-down']" class="navbar_icon-down" /></router-link>
          <div class="show_more">
            <router-link to="/add_new/post">Add new post</router-link>
          </div>
        </li>
        <li>
          <router-link to="/sr/pages"><font-awesome-icon :icon="['fas', 'copy']" class="navbar_icon" />Pages<font-awesome-icon :icon="['fas', 'caret-down']" class="navbar_icon-down" /></router-link>
           <div class="show_more">
            <router-link to="/add_new/page">Add new page</router-link>
          </div>
        </li>
        <li>
          <router-link to="/sr/menus"><font-awesome-icon :icon="['fas', 'copy']" class="navbar_icon" />Menus</router-link>
        </li>
        <li>
          <router-link to="/users"><font-awesome-icon :icon="['fas', 'user']" class="navbar_icon" />Users</router-link>
        </li>
        <li v-if="isLoggedIn" class="logOutButton">
          <a @click="logOut">Logout <font-awesome-icon :icon="['fas', 'sign-out-alt']" /></a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import isLoggedIn from '@/Helpers/isLoggedIn'
import router from '@/router'

export default {
  name: 'Navbar',
  data () {
    return {
      info: [],
      isLoggedIn: isLoggedIn()
    }
  },
  methods: {
    logOut () {
      window.$cookies.remove('bearerToken')
      this.$store.state.user = {}
      router.push('/')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    nav{
      display: block;
      width: 100%;
      ul {
        list-style: none;
        padding-left: 0;
        li{
          display: block;
          text-align: left;
          margin: 20px 0;
          position: relative;
          a {
            text-decoration: none;
            text-transform: capitalize;
            color: white;
            font-size: 14px;
            display: flex;
            padding: 12px 20px 12px 20px;
            margin: 0 5px;
            align-items: center;
            transition: all .2s ease;
          }
          a:hover {
            background-color: rgba(66,185,131,0.3);
            border-radius: 3px;
          }
          a.router-link-active {
            background-color: #42b983;
            border-radius: 3px;
          }
          .show_more {
          }
        }
        li.logOutButton {
          position: absolute;
          bottom: 20px;
          left: 50%;
          width: 90%;
          margin: 0 auto;
          transform: translateX(-50%);
          a {
            padding: 10px 30px;
            background-color: #9c27b0;
            display: block;
            width: 100%;
            border-radius: 10px;
            color: white;
            text-transform: uppercase;
            text-align: center;
            margin-left: 0;
          }
        }
        .navbar_icon {
          font-size: 18px;
          margin-right: 5px;
          min-width: 25px;
        }
        .navbar_icon-down {
          font-size: 18px;
          position: absolute;
          right: 20px;
        }
      }
    }
</style>
