<template>
  <div class="single-post">
    <Notification v-if="notification.message" :message="notification.message" :type="notification.type"
                  :show="true"></Notification>
    <v-app-bar app>
      <font-awesome-icon :icon="['fas', 'newspaper']" class="navbar_icon"/>
      Edit post
    </v-app-bar>
    <div>
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <form @submit.prevent="submit">
          <div class="container">
            <div class="row">
              <div class="col-md-8 single_post_box">
                <div class="row">
                  <div class="col-md-12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Title"
                      rules="required|max:255"
                    >
                      <v-text-field
                        v-model="postData.title"
                        :error-messages="errors"
                        filled
                        :counter="255"
                        required
                        :value="postData.title"
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-12">
                    <tiptap v-model="postData.content"/>
                  </div>
                  <div class="col-md-12">
                    <Metadata @onSave="getMetaData" ref="childRef" :metadata="postData.metadata" />
                  </div>
                </div>
              </div>
              <div class="col-md-4 single_post_sidebar">
                <div class="row">
                  <div class="col-md-12">
                    <div class="buttons_section single_post_box">
                      <v-btn
                        class="mr-4"
                        type="submit"
                        :disabled="invalid"
                      >
                        Publish
                      </v-btn>
                      <v-btn
                        v-if="posts.length === 0"
                        class="mr-4"
                        @click='overwriteEN'
                      >
                        Overwrite
                      </v-btn>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="post_status single_post_box">
                      <h3 class="single_post_box__h3">Post status</h3>
                      <v-select
                        v-model="postData.status"
                        :items="postData.statuses"
                        item-text="name"
                        item-value="abbr"
                        label="Select"
                        persistent-hint
                        return-object
                        single-line
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="post_status single_post_box">
                      <h3 class="single_post_box__h3">Post informations</h3>
                      <p>Created by <b>{{ postData.author }}</b> at <b>{{ postData.createdAt | formatDate }}</b></p>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="post_categories single_post_box">
                      <h3 class="single_post_box__h3">Post categories</h3>
                      <div class="categories_box">
                        <div v-for="(category, index) in categories" :key="index"
                             class="categories_box__single_category">
                          <input
                            type="checkbox"
                            name="categories"
                            :id="`category-`+category.id"
                            v-model="postData.categories"
                            :value="category.main_category_id"
                            :label="category.name"
                            class="category_checkbox"
                          >
                          <label :for="`category-`+category.id">
                            {{ category.name }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="single_post_box">
                      <h3 class="single_post_box__h3">Featured Image</h3>
                      <ImageSelectorInput v-model="postData.featuredImage" :multiple="false"
                                          :featuredImageSlug="postData.featuredImageSlug"></ImageSelectorInput>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import myApi from '@/services/myApi'
import Notification from '@/components/Notification'
import { getSinglePost, getAllLanguages } from '@/services/post'
import { getAllCategories } from '@/services/category'
import ImageSelectorInput from '@/components/ImageSelectorInput'
import Tiptap from '@/components/Tiptap/Tiptap'
import router from '@/router'
import Metadata from '../Meta/Metadata.vue'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})'
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}'
})

extend('email', {
  ...email,
  message: 'Email must be valid'
})

export default {
  name: 'SinglePost',
  components: {
    ValidationProvider,
    ValidationObserver,
    Notification,
    ImageSelectorInput,
    Tiptap,
    Metadata
  },
  data () {
    return {
      selectedImage: null,
      notification: {
        message: '',
        type: ''
      },
      lang: this.$route.params.lang,
      id: this.$route.params.id,
      posts: [],
      allLanguages: [],
      someBool: true,
      categories: [],
      postData: {
        title: '',
        content: '',
        author: '',
        createdAt: '',
        featuredImageSlug: '',
        featuredImage: '',
        lang: '',
        status: {},
        statuses: [
          {
            id: 2,
            name: 'Publish'
          },
          {
            id: 1,
            name: 'Draft'
          }
        ],
        categories: [],
        metadata: {}
      }
    }
  },
  methods: {
    getMetaData (metadata) {
      this.postData.metadata = metadata
    },
    returnMetadata () {
      this.$refs.childRef.metadataCallback()
    },
    submit () {
      this.returnMetadata()
      this.$root.isLoading = true
      if (this.posts.length === 0) {
        myApi.post('api/' + this.lang + '/posts/' + this.id, {
          lang: this.lang,
          title: this.postData.title,
          post_content: this.postData.content,
          status_id: this.postData.status.id,
          featured_image_id: this.postData.featuredImage,
          categories: this.postData.categories,
          metadata: this.postData.metadata
        }).then(results => {
          console.log(results)
          if (results.status === 200) {
            this.notification.message = 'Post is successfully added.'
            this.notification.type = 'success'
          } else {
            this.notification.message = results.data.message
            this.notification.type = 'error'
          }
          var lang = this.lang
          setTimeout(function () {
            router.push(`/${lang}/posts`)
          }, 1500)
          this.$root.isLoading = false
        }
        ).catch((error) => {
          if (Object.prototype.hasOwnProperty.call(error.response.data.errors, 'title')) {
            this.notification.message = 'This title is already taken by other post!'
            this.notification.type = 'error'
          } else if (Object.prototype.hasOwnProperty.call(error.response.data.errors, 'status_id')) {
            this.notification.message = 'Please select post status!'
            this.notification.type = 'error'
          }
          this.$root.isLoading = false
        })
      } else {
        myApi.put('api/' + this.lang + '/posts/' + this.postData.postID, {
          title: this.postData.title,
          post_content: this.postData.content,
          status_id: this.postData.status.id,
          featured_image_id: this.postData.featuredImage,
          categories: this.postData.categories,
          metadata: this.postData.metadata
        }).then(results => {
          console.log(results)
          if (results.status === 200) {
            this.notification.message = 'Post is successfully edited.'
            this.notification.type = 'success'
          } else {
            this.notification.message = results.data.message
            this.notification.type = 'error'
          }
          this.$root.isLoading = false
        }).catch((error) => {
          if (Object.prototype.hasOwnProperty.call(error.response.data.errors, 'title')) {
            this.notification.message = 'This title is already taken by other post!'
            this.notification.type = 'error'
          } else if (Object.prototype.hasOwnProperty.call(error.response.data.errors, 'status_id')) {
            this.notification.message = 'Please select post status!'
            this.notification.type = 'error'
          }
          this.$root.isLoading = false
        })
      }
    },
    overwriteEN () {
      this.$root.isLoading = true
      this.allLanguages.map((language) => {
        getSinglePost(language, this.id).then((post) => {
          var posts = post.data.post
          posts.map((key) => {
            this.postData.title = key.title
            this.postData.status.name = key.status.name.charAt(0).toUpperCase() + key.status.name.slice(1)
            this.postData.status.id = key.status.id
            if (key.featured_image) {
              this.postData.featuredImageSlug = key.featured_image.slug
              this.postData.featuredImage = key.featured_image.id
            }
            this.postData.statuses.push(key.status.name.charAt(0).toUpperCase() + key.status.name.slice(1))
            if (key.content === null) {
            } else {
              this.postData.content = key.content
            }
            this.postData.postID = key.id
            this.postData.statusID = key.status.id
          })
          var categories = post.data.category_relation
          categories.map((key) => {
            var category = key.category
            category.map((categorySingle) => {
              this.postData.categories.push(categorySingle.main_category_id)
            })
          })
          this.$root.isLoading = false
        })
      })
    }
  },
  mounted () {
    this.$root.isLoading = true
    getSinglePost(this.lang, this.id).then((post) => {
      var posts = post.data.post
      this.posts = post.data.post
      posts.map((key) => {
        this.postData.metadata = key.metadata
        this.postData.title = key.title
        this.postData.status.name = key.status.name.charAt(0).toUpperCase() + key.status.name.slice(1)
        this.postData.status.id = key.status.id
        if (key.featured_image) {
          this.postData.featuredImageSlug = key.featured_image.slug
          this.postData.featuredImage = key.featured_image.id
        }
        this.postData.statuses.push(key.status.name.charAt(0).toUpperCase() + key.status.name.slice(1))
        if (key.content === null) {
        } else {
          this.postData.content = key.content
        }
        this.postData.postID = key.id
        this.postData.statusID = key.status.id
        this.postData.author = key.author.name
        this.postData.createdAt = key.created_at
      })
      var categories = post.data.category_relation
      categories.map((key) => {
        var category = key.category
        category.map((categorySingle) => {
          this.postData.categories.push(categorySingle.main_category_id)
        })
      })
      getAllCategories(this.lang).then(categories => {
        var categoryArray = []
        categories.data.data.map(category => {
          category.category.map(singleCat => {
            categoryArray.push(singleCat)
          })
        })
        this.categories = categoryArray
        this.$root.isLoading = false
      })
    })
    getAllLanguages().then((languages) => {
      Object.values(languages).map(lang => {
        this.allLanguages.push(lang)
      })
    })
  }
}

</script>

<style scoped>
ul {
  list-style: none;
  padding-left: 0;
}

li {
  display: block;
  font-size: 20px;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0 15px 0;
}

h3 {
  display: block;
}

a {
  font-size: 14px;
}

.my-text-style >>> .v-input--selection-controls__ripple {
  border-radius: 0;
  border: 1px solid #f2f2f2;
}

.buttons_section {
  text-align: right;
}

.buttons_section .theme--light.v-btn.v-btn--has-bg {
  background-color: #42b983;
  box-shadow: none;
  color: white;
}

.v-input--is-label-active.my-text-style >>> .v-input--selection-controls__ripple:before {
  background-color: red;
}

.categories_box__single_category {
  margin: 15px 0;
  display: flex;
  align-content: center;
  align-items: center;
}

.categories_box__single_category:first-child {
  margin-top: 0;
}

.categories_box {
  padding: 10px;
  height: 180px;
  overflow: auto;
}

.single_post_box {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}

.single_post_sidebar {
  padding: 0;
  padding-left: 30px;
}

.single_post_box__h3 {
  margin-bottom: 15px;
}

.category_checkbox:active, .category_checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.category_checkbox {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  margin-right: 5px;
}

.category_checkbox:checked {
  background-color: #e9ecee;
  border: 1px solid #42b983;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}

.category_checkbox:checked:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: #42b983;
}

</style>
