<template>
  <div class="users">
    <v-app-bar app>
      Users
    </v-app-bar>
    <router-link :to="{ name: 'register' }" class="primary_btn">Add new</router-link>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th></th>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Email
          </th>
          <th class="text-left">
            Role
          </th>
          <th>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(user, idx) in allUsers" :key="idx">
          <td>{{idx + 1}}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.roles[0].name }}</td>
          <td>
            <router-link :to="{ name: 'edit-user', params: { id: user.id } }" class="edit_btn">Edit</router-link>
            <v-btn
              small
              depressed
              color="error"
              @click.stop="dialog = true, user_id = user.id"
              class="delete_user"
            >
              Delete
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <router-view></router-view>
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="">
          Are you sure you want to delete this user?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="delete_user(user_id)"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { getAllUsers } from '@/services/user'
import myApi from '@/services/myApi'

export default {
  name: 'users',
  data () {
    return {
      allUsers: [],
      headers: [
        {
          text: ''
        },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' }
      ],
      dialog: false,
      user_id: undefined
    }
  },
  mounted () {
    this.$root.isLoading = true
    getAllUsers().then(users => {
      this.$root.isLoading = false
      this.allUsers = users.data
    })
  },
  methods: {
    delete_user (id) {
      myApi.delete(`api/auth/user/delete/${id}`).then((response) => {
        getAllUsers().then(users => {
          this.allUsers = users.data
          this.dialog = false
        })
      }
      ).catch(() => {
      })
    }
  }
}

</script>

<style scoped>
.primary_btn {
  padding: 5px 15px;
  display: inline-block;
  border-radius: 5px;
  color: #fff;
  margin-bottom: 15px;
  text-decoration: none;
  background-color: #42b983;
}
.edit_btn {
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
  margin-bottom: 15px;
  text-decoration: none;
  background-color: #42b983;
}
.delete_user{
  height: 25px!important;
  margin-left: 10px;
}
</style>
