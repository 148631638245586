<template>
  <div>
    <template v-for="(item, index) in items">
      <div class="divider" v-if="item.type === 'divider'" :key="index"/>
      <menu-item v-else :key="index" v-bind="item"/>
    </template>

    <file-selector ref="fileSelect"
                   :mediaType="mediaType"
                   :fileSelect="fileSelect"
                   :uploadGallery="uploadGallery"
                   :showButton="false"/>
    <v-dialog
      v-model="link_popup"
      width="500"
    >
      <v-card-title class="text-h5 grey lighten-2">
        <h3 class="categories_popup__h3">Insert url </h3>
      </v-card-title>
      <v-card>
        <v-card-text class="link_section_popup">
          <v-text-field
            v-model="text_link"
            label="Link"
            clearable
          ></v-text-field>
          <v-btn @click="setLinkUrl()" class="button_style_1">Insert link</v-btn>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue'
import FileSelector from './FileSelector'

export default {
  components: {
    MenuItem,
    FileSelector
  },
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      link_popup: false,
      link_url: '',
      text_link: '',
      mediaType: 'image',
      items: [
        {
          icon: 'paragraph',
          title: 'Paragraph',
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive('paragraph')
        },
        {
          icon: 'h-1',
          title: 'Heading 1',
          action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 1 })
        },
        {
          icon: 'h-2',
          title: 'Heading 2',
          action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 2 })
        },
        {
          icon: 'h-3',
          title: 'Heading 3',
          action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 3 })
        },
        {
          icon: 'h-4',
          title: 'Heading 4',
          action: () => this.editor.chain().focus().toggleHeading({ level: 4 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 4 })
        },
        {
          icon: 'list-unordered',
          title: 'Bullet List',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList')
        },
        {
          icon: 'list-ordered',
          title: 'Ordered List',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList')
        },
        {
          type: 'divider'
        },
        {
          icon: 'bold',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold')
        },
        {
          icon: 'italic',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic')
        },
        {
          icon: 'strikethrough',
          title: 'Strike',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike')
        },
        {
          icon: 'code-view',
          title: 'Code',
          action: () => this.editor.chain().focus().toggleCode().run(),
          isActive: () => this.editor.isActive('code')
        },
        {
          type: 'divider'
        },
        {
          icon: 'image-2-fill',
          title: 'Image',
          action: () => this.openModal('image')
        },
        {
          icon: 'gallery-line',
          title: 'Gallery',
          action: () => this.openModal('gallery')
        },
        {
          icon: 'file-copy-2-fill',
          title: 'Documents',
          action: () => this.openModal('document')
        },
        {
          icon: 'link-m',
          title: 'Insert Link',
          action: () => this.openLinkPopup()
        },
        {
          icon: 'link-unlink-m',
          title: 'Unlink',
          action: () => this.editor.chain().focus().unsetLink().run()
        },
        {
          type: 'divider'
        },
        {
          icon: 'double-quotes-l',
          title: 'Blockquote',
          action: () => this.editor.chain().focus().toggleBlockquote().run(),
          isActive: () => this.editor.isActive('blockquote')
        },
        {
          icon: 'separator',
          title: 'Horizontal Rule',
          action: () => this.editor.chain().focus().setHorizontalRule().run()
        },
        {
          type: 'divider'
        },
        {
          icon: 'text-wrap',
          title: 'Hard Break',
          action: () => this.editor.chain().focus().setHardBreak().run()
        },
        {
          icon: 'format-clear',
          title: 'Clear Format',
          action: () => this.editor.chain()
            .focus()
            .clearNodes()
            .unsetAllMarks()
            .run()
        },
        {
          type: 'divider'
        },
        {
          icon: 'arrow-go-back-line',
          title: 'Undo',
          action: () => this.editor.chain().focus().undo().run()
        },
        {
          icon: 'arrow-go-forward-line',
          title: 'Redo',
          action: () => this.editor.chain().focus().redo().run()
          // },
          // {
          //   icon: 'arrow-go-forward-line',
          //   title: 'Redo 2',
          //   action: () => {
          //     console.log('this.editor.chain().focus()', this.editor.chain().focus())
          //     return this.editor.chain().focus().setImage({ src: 'https://i.ytimg.com/vi/YeQFBS6IDAQ/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCQf0K3-40WhbzLQri3Uvvet_aHjg' }).run()
          //   }
        }
      ]
    }
  },
  methods: {

    fileSelect (file) { // { i
      if (file.media === 'image') {
        this.editor.chain().focus().setImage({ src: 'https://aktiv.w3-lab.net/' + file.properties.medium }).run()
      } else if (file.type === 'document') {
        this.editor.chain().focus().insertContent('<a href="' + file.src + '" target="_blank">' + file.name + '</a>').run()
      } else {

      }
    },

    uploadGallery (images) {
      this.editor.chain().insertContent('<p class="test13">').run()
      images.map((singleImage, index) => {
        this.editor.chain().insertContent(`<img src="https://aktiv.w3-lab.net/${singleImage.properties.thumbnail}" class="gallery gallery-${index}"></br>`).run()
        // this.editor.chain().setImage({ src: 'https://aktiv.w3-lab.net/' + singleImage.properties.medium }).run()
      })
      this.editor.chain().insertContent('</p>').run()
    },

    openLinkPopup () {
      this.link_popup = true
    },

    setLinkUrl () {
      this.link_url = this.text_link

      var url = this.link_url

      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()

      this.link_popup = false
    },

    openModal (mediaType) {
      this.mediaType = mediaType
      this.$refs.fileSelect.dialog = true
    }
  }
}
</script>

<style scoped>
.divider {
  width: 2px;
  height: 1.25rem;
  margin-left: 0.5rem;
  margin-right: 0.75rem;
}

.v-application .primary--text {
  color: #42b983 !important;
  caret-color: #42b983 !important;
}

.link_section_popup >>> .v-label.primary--text {
  color: #42b983 !important;
  caret-color: #42b983 !important;
}

.editor__header {
  display: flex;
  margin: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
  justify-content: space-between;
}

.v-dialog > .v-card > .v-card__text.link_section_popup {
  padding: 25px;
}
</style>
