<template>
  <div class="posts">
    <div class="navigation">
      <div class="navigation__item">
        <router-link to="/sr/posts">
          Posts
        </router-link>
      </div>
      <div class="navigation__item">
        <router-link to="/sr/categories">
          Categories
        </router-link>
      </div>
      <div class="navigation__item">
        <router-link to="/sr/posts/trash">
          Trash
        </router-link>
      </div>
    </div>
    <div class="posts_container">
      <v-app-bar app>
        <font-awesome-icon :icon="['fas', 'newspaper']" class="navbar_icon"/>
        Posts
      </v-app-bar>
      <div class="languages">
        <p class="languages__title">Language:</p>
        <router-link v-for="(lang, index) in allLangs"  :to="'/' + lang + '/posts'" :key="index" class="navigation_menu">
          {{ lang }}
        </router-link>
      </div>
      <div class="container">
        <div class="row post_form_title">
          <div class="col-md-3 post_form_title__columns">
            <p>Title</p>
          </div>
          <div class="col-md-3 post_form_title__columns">
            <p>Categories</p>
          </div>
          <div class="col-md-2 post_form_title__columns">
            <p>Date</p>
          </div>
          <div class="col-md-2 post_form_title__columns">
            <p>Status</p>
          </div>
          <div class="col-md-2 post_form_title__columns">
            <p>Languages</p>
          </div>
        </div>
      </div>
      <div class="posts_list">
        <div v-for="(item, index) in posts" :key="index">
          <div v-for="(item_post, index2) in item.post" :key="index2">
            <div class="row single_post_row">
              <div class="col-md-3">
                <h3 class="posts_list__title">{{ item_post.title }}</h3>
                <p class="edit_link_p">
                  <router-link v-bind:to="'/' + item_post.lang + '/post/' + item_post.main_post_id">Edit post
                  </router-link>
                  |
                  <a @click="deletePost('posts', item_post.lang, item_post.id); notificationMessage='true'">Delete post
                  </a>
                </p>
              </div>
              <div class="col-md-3">
                <v-btn
                  @click="categoryDialog(item.category_relation); dialog = true"
                  class="open_categories_popup"
                  v-if="item.category_relation.length !== 0"
                >
                  View categories
                </v-btn>
              </div>
              <div class="col-md-2">
                <p>{{ item_post.created_at | formatDate }}</p>
              </div>
              <div class="col-md-2">
                <p>{{ item_post.status.name }}</p>
              </div>
              <div class="col-md-2">
                <div class="languages__flag">
                    <img src="../../assets/srpska-zastava-jezik.jpg" alt="">
                    <img src="../../assets/bosnian-flag.jpg" alt="">
                    <img src="../../assets/turkish-flag.jpg" alt="">
                    <img src="../../assets/gorani-flag.jpg" alt="">
                    <img src="../../assets/romska-flag.jpg" alt="">
                    <img src="../../assets/alb-flag.png" alt="">
                  </div>
                <div class="add_or_edit_post_language">
                  <div v-for="(language, language_index) in item.languages" :key="language_index"
                       class="add_or_edit_post_language__single">
                    <div v-if="language">
                      <router-link v-bind:to="'/' + language_index + '/post/' + item_post.main_post_id">
                        <font-awesome-icon :icon="['fas', 'pencil-alt']" class="language_icon language_icon__edit"/>
                      </router-link>
                    </div>
                    <div v-else>
                      <router-link v-bind:to="'/' + language_index + '/post/' + item_post.main_post_id">
                        <font-awesome-icon :icon="['fas', 'plus']" class="language_icon language_icon__add"/>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination" v-if="lastPagePagination !== 1">
          <ul>
            <li v-for="(page, index) in lastPagePagination" :key="index">
              <router-link v-if="index === 0" to="posts">
                {{ page }}
              </router-link>
              <router-link v-else v-bind:to='"posts?page=" + page'>
                {{ page }}
              </router-link>
            </li>
          </ul>
        </div>
    </div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <h3 class="categories_popup__h3">Categories: </h3>
        </v-card-title>
        <v-card-text>
          <div v-for="(category,index) in categoryRelation" :key="index">
            <div class="categories_popup" v-for="(singleCategory, index2) in category.category" :key="index2">
                <p>{{ singleCategory.name }}</p>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="notificationMessage"
      width="500"
    >
      <v-card>
        <v-card-text>
          <Notification v-if="notification.message" :message="notification.message" :type="notification.type"
                  :show="true"></Notification>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { getAllPosts, getAllLanguages } from '@/services/post'
import { softDeletePostPage } from '@/services/trash'
import Notification from '@/components/Notification'

export default {
  name: 'Posts',
  components: {
    Notification
  },
  data () {
    return {
      notificationMessage: false,
      dialog: false,
      posts: [],
      categoryName: '',
      notification: {
        message: 'Post is succesfully deleted',
        type: 'success'
      },
      languages: [],
      categoryRelation: [],
      allLangs: [],
      lastPagePagination: '',
      translatedPosts: [],
      ingArr: [],
      lang: this.$route.params.lang
    }
  },
  methods: {
    deletePost (type, postLang, postId) {
      softDeletePostPage(type, postLang, postId)
    },
    categoryDialog (categoryRelation) {
      this.categoryRelation = categoryRelation
    }
  },
  mounted () {
    this.$root.isLoading = true
    getAllLanguages().then(languages => {
      this.allLangs = languages
      getAllPosts(this.lang).then(postsData => {
        this.lastPagePagination = postsData.data.last_page
        var posts = postsData.data.data
        posts.map(post => {
          const translatedOn = post.translated_post.map(translation => {
            return translation.lang
          })
          post.languages = {}
          Object.values(languages).map(lang => {
            post.languages[lang] = translatedOn.includes(lang)
          })
          return post
        })
        this.posts = posts
        this.$root.isLoading = false
      })
    })
  },
  watch: {
    $route (to) {
      this.$root.isLoading = true
      getAllLanguages().then(languages => {
        this.allLangs = languages
        getAllPosts(to.params.lang, to.query.page).then(postsData => {
          this.lastPagePagination = postsData.data.last_page
          var posts = postsData.data.data
          posts.map(post => {
            const translatedOn = post.translated_post.map(translation => {
              return translation.lang
            })
            post.languages = {}
            Object.values(languages).map(lang => {
              post.languages[lang] = translatedOn.includes(lang)
            })
            return post
          })
          this.posts = posts
          this.$root.isLoading = false
        })
      })
    }
  }
}

</script>

<style scoped>
.post_form_title {
  border-radius: 10px;
  background-color: #42b983;
  color: white;
  padding: 5px 25px;
}

.single_post_row {
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #f2f2f2;
}

.posts_list__title {
  font-size: 20px;
  font-weight: normal;
}

.post_form_title__columns {
  padding: 12px;
}

.post_form_title__columns p {
  font-size: 20px;
}

p {
  margin-bottom: 0;
}

.languages a {
  padding: 5px 15px;
  display: inline-block;
  border-radius: 5px;
  color: #42b983;
  margin-bottom: 15px;
  text-decoration: none;
  transition: all .3s ease;
}

.languages .router-link-exact-active.router-link-active {
  background-color: #42b983;
  color: #fff;
}

.theme--light.v-btn.v-btn--has-bg.open_categories_popup {
  color: #fff;
  background-color: #42b983;
  padding: 5px 25px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
}

.posts_container {
  background-color: white;
  padding: 30px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.languages__title {
  display: inline-block;
  margin-right: 10px;
}

.posts_list {
  margin-top: -10px;
  padding: 20px;
  border: 2px solid #42b983;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.row.single_post_row {
  margin: 0;
}

.edit_link_p a {
  text-decoration: none;
  font-size: 14px;
  color: #42b983;
}

.navbar_icon {
  margin-right: 15px;
}

.languages__flag img {
  margin: 0 3px;
  max-width: 30px;
}

.add_or_edit_post_language {
  display: flex;
  justify-content: space-between;
}

.languages__flag {
  display: flex;
  justify-content: space-between;
}

.add_or_edit_post_language__single {
  max-width: 30px;
  margin-top: 10px;
  text-align: center;
  min-width: 30px;
}

.language_icon {
  font-size: 14px;
}

.v-dialog > .v-card > .v-card__text {
  padding: 25px;
}

.alert_notification.success {
  margin-bottom: 0;
}

.language_icon.language_icon__add {
  color: #42b983;
}

.language_icon.language_icon__edit {
  color: #9c27b0;
}

.categories_popup p {
  font-size: 22px;
  color: #42b983;
  margin-bottom: 10px;
}
</style>
