<template>
  <div class="dashboard">
    <v-app-bar app>
        Dashboard
    </v-app-bar>
  </div>
</template>

<script>

export default {
  name: 'Dashboard',
  components: {
  },
  data () {
    return {
      selectedImage: null
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
