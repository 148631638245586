import myApi from '@/services/myApi'

export function getAllTrashPages (lang) {
  return myApi.get('api/' + lang + '/pages/trash')
    .then(pages => {
      return pages
    })
}

export function getAllTrashPosts (lang) {
  return myApi.get('api/' + lang + '/posts/trash')
    .then(posts => {
      return posts
    })
}

export function softDeletePostPage (type, lang, id) {
  return myApi.delete('api/' + lang + '/' + type + '/' + id)
    .then(
      softDelete => {
        console.log(softDelete)
      }
    )
}

export function permanentDeletePostPage (type, lang, id) {
  return myApi.delete('api/' + lang + '/' + type + '/trash/' + id)
    .then(
      permanentDelete => {
        console.log(permanentDelete)
      }
    )
}

export function restorePostPage (type, lang, id) {
  return myApi.post('api/' + lang + '/' + type + '/trash/' + id)
    .then(
      restore => {
        console.log(restore)
      }
    )
}
