import myApi from '@/services/myApi'

export function getAllMedia () {
  return myApi.get('/api/media')
    .then((result) => {
      return result
    })
}

export function insertMedia (file) {
  return myApi.post('/api/media', {
    file: file
  })
}

export function getAllDocuments () {
  return myApi.get('/api/media/documents')
    .then((result) => {
      return result
    })
}

export function getAllImages () {
  return myApi.get('api/media/images')
    .then((result) => {
      return result
    })
}
