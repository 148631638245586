import myApi from '@/services/myApi'

export function getAllMenus () {
  return myApi.get('api/menu')
    .then((res) => {
      return res.data
    })
}

export function getAllMenuItem (lang, id) {
  return myApi.get(`api/${lang}/menu/${id}`)
    .then((res) => {
      return res.data.menu_items
    })
}

export function searchItem (lang, type, search) {
  return myApi.get(`api/${lang}/${type}?per_page=10&search=${search}`)
    .then((res) => {
      return res.data.data
    })
}
