<template>
  <div class="single-post">
     <Notification v-if="notification.message" :message="notification.message" :type="notification.type"
                  :show="true"></Notification>
    <v-app-bar app>
      <font-awesome-icon :icon="['fas', 'copy']" class="navbar_icon"/>
      Add new page
    </v-app-bar>
    <div>
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <form @submit.prevent="submit">
          <div class="container">
            <div class="row">
              <div class="col-md-8 single_post_box">
                <div class="row">
                  <div class="col-md-12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Title"
                      rules="required|max:255"
                    >
                      <v-text-field
                        v-model="pageData.title"
                        :counter="255"
                        :error-messages="errors"
                        filled
                        required
                        :value="pageData.title"
                      ></v-text-field>
                    </validation-provider>
                  </div>
                  <div class="col-md-12">
                    <tiptap v-model="pageData.content"/>
                  </div>
                </div>
              </div>
              <div class="col-md-4 single_post_sidebar">
                <div class="buttons_section single_post_box">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="invalid"
                  >
                    Publish
                  </v-btn>
                </div>
                <div class="post_status single_post_box">
                  <h3 class="single_post_box__h3">Page informations</h3>
                  <p>Created by <b>{{ pageData.author }}</b>  at <b>{{ pageData.createdAt | formatDate }}</b></p>
                </div>
                <div class="post_status single_post_box">
                  <h3 class="single_post_box__h3">Page status</h3>
                  <v-select
                    v-model="pageData.status"
                    :items="pageData.statuses"
                    item-text="name"
                    item-value="abbr"
                    label="Select"
                    persistent-hint
                    return-object
                    single-line
                  ></v-select>
                </div>
                <div class="post_status single_post_box">
                  <h3 class="single_post_box__h3">Featured Image</h3>
                  <ImageSelectorInput v-model="pageData.featuredImage" :multiple="false"
                                      :featuredImageSlug="pageData.featuredImageSlug"></ImageSelectorInput>
                </div>
              </div>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import ImageSelectorInput from '@/components/ImageSelectorInput'
import Tiptap from '@/components/Tiptap/Tiptap'
import myApi from '@/services/myApi'
import Notification from '@/components/Notification'
import { getSinglePage } from '@/services/page'
import router from '@/router'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})'
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}'
})

extend('email', {
  ...email,
  message: 'Email must be valid'
})

export default {
  name: 'NewPost',
  props: {
    editPage: Boolean
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ImageSelectorInput,
    Tiptap,
    Notification
  },
  data () {
    return {
      lang: this.$route.params.lang,
      id: this.$route.params.id,
      translation: false,
      notification: {
        message: '',
        type: ''
      },
      someBool: true,
      pageData: {
        id: '',
        title: '',
        content: '',
        author: '',
        createdAt: '',
        featuredImageSlug: '',
        featuredImage: '',
        status: {},
        statuses: [
          {
            id: 2,
            name: 'Publish'
          },
          {
            id: 1,
            name: 'Draft'
          }
        ],
        statusID: ''
      }
    }
  },
  methods: {
    submit () {
      this.$root.isLoading = true
      if (this.translation) {
        myApi.post('api/' + this.lang + '/pages/' + this.id, {
          lang: this.lang,
          title: this.pageData.title,
          page_content: this.pageData.content,
          status_id: this.pageData.status.id,
          featured_image_id: this.pageData.featuredImage
        }).then(result => {
          if (result.status === 200) {
            this.notification.message = 'Page is successfully added.'
            this.notification.type = 'success'
          }
          this.$root.isLoading = false
          var lang = this.lang
          setTimeout(function () {
            router.push(`/${lang}/pages`)
          }, 1500)
        }
        ).catch((error) => {
          if (Object.prototype.hasOwnProperty.call(error.response.data.errors, 'title')) {
            this.notification.message = 'This title is already taken by other page!'
            this.notification.type = 'error'
          } else if (Object.prototype.hasOwnProperty.call(error.response.data.errors, 'status_id')) {
            this.notification.message = 'Please select page status!'
            this.notification.type = 'error'
          }
          this.$root.isLoading = false
        })
      } else {
        myApi.put('api/' + this.lang + '/pages/' + this.pageData.id, {
          title: this.pageData.title,
          page_content: this.pageData.content,
          status_id: this.pageData.status.id,
          featured_image_id: this.pageData.featuredImage
        }).then(result => {
          if (result.status === 200) {
            this.notification.message = 'Page is successfully edited.'
            this.notification.type = 'success'
          }
          this.$root.isLoading = false
        }
        ).catch((error) => {
          if (Object.prototype.hasOwnProperty.call(error.response.data.errors, 'title')) {
            this.notification.message = 'This title is already taken by other page!'
            this.notification.type = 'error'
          } else if (Object.prototype.hasOwnProperty.call(error.response.data.errors, 'status_id')) {
            this.notification.message = 'Please select page status!'
            this.notification.type = 'error'
          }
          this.$root.isLoading = false
        })
      }
    }
  },
  mounted () {
    this.$root.isLoading = true
    getSinglePage(this.lang, this.id)
      .then(page => {
        if (Object.keys(page).length === 0) {
          this.translation = true
        } else {
          this.pageData.title = page.title
          this.pageData.status.name = page.status.name.charAt(0).toUpperCase() + page.status.name.slice(1)
          this.pageData.status.id = page.status.id
          if (page.featured_image) {
            this.pageData.featuredImageSlug = page.featured_image.slug
            this.pageData.featuredImage = page.featured_image.id
          }
          this.pageData.statuses.push(page.status.name.charAt(0).toUpperCase() + page.status.name.slice(1))
          if (page.content === null) {
          } else {
            this.pageData.content = page.content
          }
          this.pageData.id = page.id
          this.pageData.author = page.author.name
          this.pageData.createdAt = page.created_at
        }
        this.$root.isLoading = false
      })
  },
  watch: {

  }
}

</script>

<style scoped>
ul {
  list-style: none;
  padding-left: 0;
}

li {
  display: block;
  font-size: 20px;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0 15px 0;
}

h3 {
  display: block;
}

a {
  font-size: 14px;
}

.my-text-style >>> .v-input--selection-controls__ripple {
  border-radius: 0;
  border: 1px solid #f2f2f2;
}

.buttons_section {
  margin-bottom: 30px;
  text-align: right;
}

.buttons_section .theme--light.v-btn.v-btn--has-bg {
  background-color: #42b983;
  box-shadow: none;
  color: white;
}

.my-text-style >>> .v-input--selection-controls__ripple {
  border-radius: 0;
  border: 1px solid #f2f2f2;
}

.buttons_section {
  margin-bottom: 30px;
  text-align: right;
}

.buttons_section .theme--light.v-btn.v-btn--has-bg {
  background-color: #42b983;
  box-shadow: none;
  color: white;
}

.v-input--is-label-active.my-text-style >>> .v-input--selection-controls__ripple:before {
  background-color: red;
}

.post_status {
  margin-bottom: 30px;
}

.categories_box__single_category {
  margin: 15px 0;
  display: flex;
  align-content: center;
  align-items: center;
}

.categories_box__single_category:first-child {
  margin-top: 0;
}

.categories_box {
  padding: 10px;
  height: 300px;
  overflow: auto;
}

.single_post_box {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}

.single_post_sidebar {
  padding: 0;
  padding-left: 30px;
}

.single_post_box__h3 {
  margin-bottom: 15px;
}

.category_checkbox:active, .category_checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.category_checkbox {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  margin-right: 5px;
}

.category_checkbox:checked {
  background-color: #e9ecee;
  border: 1px solid #42b983;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}

.category_checkbox:checked:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: #42b983;
}

</style>
