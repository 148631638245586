<template>
  <div>
    <div class="navigation">
      <div class="navigation__item">
        <router-link to="/sr/posts">
          Posts
        </router-link>
      </div>
      <div class="navigation__item">
        <router-link to="/sr/categories">
          Categories
        </router-link>
      </div>
      <div class="navigation__item">
        <router-link to="/sr/posts/trash">
          Trash
        </router-link>
      </div>
    </div>
    <v-dialog
        v-model="dialog"
        width="500"
      >

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Categories
          </v-card-title>

          <v-card-text>
             <Notification v-if="notification.message" :message="notification.message" :type="notification.type"
                    :show="true" class="notification__popup--alert"></Notification>
            <div class="close_popup" @click="dialog=false">
              X
            </div>
            <validation-observer
              ref="observer"
              v-slot="{ invalid }"
            >
              <form @submit.prevent="addNew, editCategory, addTranslation">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12 single_post_box">
                      <div class="lang-top">
                        <p>Lang:</p>
                        <select v-model="langNew.name" class="lang-top_select">
                          {{ langNew.name }}
                          <option v-for="(lang, langIndex) in allLangs" :key="langIndex">
                            {{ lang }}
                          </option>
                        </select>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Title"
                            rules="required|max:255"
                          >
                            <v-text-field
                              v-model="categoryData.title"
                              :counter="255"
                              :error-messages="errors"
                              label="Category title"
                              filled
                              required
                              class="category__title"
                            ></v-text-field>
                          </validation-provider>
                          <validation-provider
                          name="CategoryDescription">
                            <v-textarea
                              v-model="categoryData.description"
                              name="input-7-1"
                              label="Category description"
                              class="category__text-area"
                            ></v-textarea>
                          </validation-provider>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 buttons_section single_post_box">
                      <v-btn
                        type="submit"
                        @click="addNew"
                        :disabled="invalid"
                        v-if="type === 'new'"
                      >
                        Add new
                      </v-btn>
                      <v-btn
                        type="submit"
                        @click="editCategory"
                        :disabled="invalid"
                        v-else-if="type === 'edit'"
                      >
                        Edit
                      </v-btn>
                      <v-btn
                        type="submit"
                        @click="addTranslation"
                        :disabled="invalid"
                        v-else
                      >
                        Translate
                      </v-btn>
                    </div>
                  </div>
                </div>
              </form>
            </validation-observer>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    <div class="categories">
      <v-app-bar app>
        <font-awesome-icon :icon="['fas', 'newspaper']" class="navbar_icon"/>
        Categories
        <div class="categories__button_sec">
          <v-btn
            @click="dialog = true;  popupForm('new',langNew.name)"
            class="categories__button__add-new"
          >
            Add new category
          </v-btn>
        </div>
      </v-app-bar>
      <div class="languages">
        <p class="languages__title">Language:</p>
        <router-link v-for="(lang, index) in allLangs"  :to="'/' + lang + '/categories'" :key="index" class="navigation_menu">
          {{ lang }}
        </router-link>
      </div>
        <div class="container">
          <div class="row category_form_title">
            <div class="col-md-4 category_form_title__columns">
              <p>Title</p>
            </div>
            <div class="col-md-4 category_form_title__columns">
              <p>Description</p>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-3 category_form_title__columns">
              <p>Languages</p>
            </div>
          </div>
        </div>
        <div class="categories_list">
          <div v-for="(category, index) in categories" :key="index">
            <div v-for="(translated_category, index2) in category.category" :key="index2">
              <div class="row single_category_row">
                <div class="col-md-4">
                  <h3 class="category_list__title">{{ translated_category.name }}</h3>
                  <p class="edit_link_p">
                    <router-link
                      v-bind:to="'/' + translated_category.lang + '/category/' + translated_category.main_category_id">
                      <a @click="dialog = true; popupForm('edit', translated_category.lang, translated_category.main_category_id)">
                        Edit category |
                      </a>
                      <a @click="deleteCategory(translated_category.id); deletedNotification='true'">Delete</a>
                    </router-link>
                  </p>
                </div>
                <div class="col-md-4">
                  {{ translated_category.description }}
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-3">
                  <div class="languages__flag">
                    <img src="../../assets/srpska-zastava-jezik.jpg" alt="">
                    <img src="../../assets/bosnian-flag.jpg" alt="">
                    <img src="../../assets/turkish-flag.jpg" alt="">
                    <img src="../../assets/gorani-flag.jpg" alt="">
                    <img src="../../assets/romska-flag.jpg" alt="">
                    <img src="../../assets/alb-flag.png" alt="">
                  </div>
                  <div class="add_or_edit_post_language">
                    <div v-for="(language, language_index) in category.languages" :key="language_index"
                         class="add_or_edit_post_language__single">
                      <div v-if="language">
                        <a @click="dialog = true; popupForm('edit', language_index, translated_category.main_category_id, false)">
                          <font-awesome-icon :icon="['fas', 'pencil-alt']" class="language_icon language_icon__edit"/>
                        </a>
                      </div>
                      <div v-else>
                        <a @click="dialog = true; popupForm('translate', language_index, translated_category.main_category_id, true)">
                          <font-awesome-icon :icon="['fas', 'plus']" class="language_icon language_icon__add"/>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination" v-if="lastPagePagination !== 1">
          <ul>
            <li v-for="(page, index) in lastPagePagination" :key="index">
              <router-link v-if="index === 0" to="categories">
                {{ page }}
              </router-link>
              <router-link v-else v-bind:to='"categories?page=" + page'>
                {{ page }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <v-dialog
        v-model="deletedNotification"
        width="500"
      >
        <v-card>
          <v-card-text class="notificationMessage__card--text">
            <Notification v-if="notificationDeleted.message" :message="notificationDeleted.message" :type="notificationDeleted.type"
                    :show="true"></Notification>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { getAllCategories, getSingleCategory, deleteSingleCategory } from '@/services/category'
import myApi from '@/services/myApi'
import { getAllLanguages } from '@/services/post'
import Notification from '@/components/Notification'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})'
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}'
})

extend('email', {
  ...email,
  message: 'Email must be valid'
})

export default {
  name: 'Category',
  props: {
    menuName: String
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    Notification
  },
  data () {
    return {
      dialog: false,
      notificationMessage: false,
      deletedNotification: false,
      editor: ClassicEditor,
      categories: [],
      allLangs: [],
      lastPagePagination: '',
      type: '',
      notification: {
        message: '',
        type: ''
      },
      notificationDeleted: {
        message: '',
        type: ''
      },
      langNew: {
        name: this.$route.params.lang
      },
      lang: this.$route.params.lang,
      id: this.$route.params.id,
      categoryData: {
        title: '',
        description: '',
        language: '',
        languages: [
          {
            name: 'sr'
          },
          {
            name: 'en'
          },
          {
            name: 'al'
          }
        ]
      }
    }
  },
  methods: {
    addNew () {
      this.$root.isLoading = true
      myApi.post('api/' + this.langNew.name + '/category', {
        name: this.categoryData.title,
        lang: this.langNew.name,
        description: this.categoryData.description
      })
        .then(response => {
          console.log(response)
          this.$root.isLoading = false
          this.notification.message = 'Category is successfully added.'
          this.notification.type = 'success'
          this.categoryData.title = ''
          this.categoryData.description = ''
        }).catch((error) =>
          console.log(error))
    },
    editCategory () {
      this.$root.isLoading = true
      myApi.put('api/' + this.langNew.name + '/category/' + this.id, {
        name: this.categoryData.title,
        description: this.categoryData.description
      })
        .then(response => {
          console.log(response)
          this.notification.message = 'Category is successfully updated.'
          this.notification.type = 'success'
          this.$root.isLoading = false
        })
    },
    addTranslation () {
      this.$root.isLoading = true
      myApi.post('api/' + this.langNew.name + '/category/' + this.id, {
        name: this.categoryData.title,
        lang: this.langNew.name,
        description: this.categoryData.description
      })
        .then(response => {
          console.log(response)
          this.notification.message = 'Category is successfully translated.'
          this.notification.type = 'success'
          this.$root.isLoading = false
          this.categoryData.title = ''
          this.categoryData.description = ''
        })
    },
    popupForm (type, lang, id, translate) {
      this.langNew.name = lang
      this.id = id
      if (id === undefined) {
        this.categoryData.title = ''
      }
      if (type === 'translate') {
        this.categoryData.title = ''
      }
      if (!translate) {
        if (typeof id !== 'undefined') {
          this.$root.isLoading = true
          getSingleCategory(lang, id).then(
            response => {
              this.categoryData.title = response.category.name
              this.langNew.name = response.category.lang
              this.id = response.category.id
              this.categoryData.description = response.category.description
            },
            this.$root.isLoading = false
          )
        }
      }
      this.type = type
    },
    deleteCategory (id) {
      this.$root.isLoading = true
      deleteSingleCategory(id)
      this.notificationDeleted.message = 'Category is successfully deleted'
      this.notificationDeleted.type = 'success'
      this.$root.isLoading = false
    }
  },
  mounted () {
    this.$root.isLoading = true
    getAllLanguages().then(languages => {
      this.allLangs = languages
      getAllCategories(this.lang).then(categoriesData => {
        this.lastPagePagination = categoriesData.data.last_page
        var categories = categoriesData.data.data
        categories.map(category => {
          const translatedOn = category.translated_category.map(translation => {
            return translation.lang
          })
          category.languages = {}
          Object.values(languages).map(lang => {
            category.languages[lang] = translatedOn.includes(lang)
          })
          return category
        })
        this.categories = categories
        this.$root.isLoading = false
      })
    })
  },
  watch: {
    $route (to) {
      this.$root.isLoading = true
      this.langNew.name = to.params.lang
      getAllLanguages().then(languages => {
        this.allLangs = languages
        getAllCategories(to.params.lang, to.query.page).then(categoriesData => {
          this.lastPagePagination = categoriesData.data.last_page
          var categories = categoriesData.data.data
          categories.map(category => {
            const translatedOn = category.translated_category.map(translation => {
              return translation.lang
            })
            category.languages = {}
            Object.values(languages).map(lang => {
              category.languages[lang] = translatedOn.includes(lang)
            })
            return category
          })
          this.categories = categories
          this.$root.isLoading = false
        })
      })
    }
  }
}

</script>

<style scoped>
.category_form_title {
  border-radius: 10px;
  background-color: #42b983;
  color: white;
  padding: 0 25px;
}

.single_post_box {
  position: relative;
  padding-top: 40px;
}

.single_category_row {
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #f2f2f2;
}

.category_list__title {
  font-size: 20px;
  font-weight: normal;
}

.category_form_title__columns {
  padding: 12px;
}

.close_popup {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.category_form_title__columns p {
  font-size: 20px;
}

.add_new_category {
  position: fixed;
  display: none;
  left: 50%;
  top: 50%;
  width: 25%;
  background-color: white;
  border-radius: 10px;
  padding: 50px 30px;
  transform: translate(-50%, -50%);
  box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.5);
}

.categories__button__add-new {
  padding: 8px 25px;
  background-color: white;
  color: #42b983;
  border-radius: 5px;
  border: 1px solid white;
  text-transform: uppercase;
}

.categories__button_sec {
  position: absolute;
  right: 15px;
}

p {
  margin-bottom: 0;
}

.languages a {
  padding: 5px 15px;
  display: inline-block;
  border-radius: 5px;
  color: #42b983;
  margin-bottom: 15px;
  text-decoration: none;
  transition: all .3s ease;
}

.languages .router-link-exact-active.router-link-active {
  background-color: #42b983;
  color: #fff;
}

.categories {
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 30px;
}

.languages__title {
  display: inline-block;
  margin-right: 10px;
}

.categories_list {
  margin-top: -10px;
  padding: 20px;
  border: 2px solid #42b983;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.row.single_category_row {
  margin: 0;
}

.edit_link_p a {
  text-decoration: none;
  font-size: 14px;
  color: #42b983;
}

.navbar_icon {
  margin-right: 15px;
}

.new_category_title {
  display: inline-block;
}

.lang-top {
  position: absolute;
  right: 13px;
  top: -6px;
  text-align: right;
}

.lang-top p {
  display: inline-block;
  padding-right: 15px;
}

.buttons_section {
  text-align: right;
}

.new_category_title {
  margin-bottom: 20px;
}

.lang-top_select {
  background-color: #42b983;
  color: #fff;
  padding: 3px 5px;
  border-radius: 5px;
  text-transform: uppercase;
}

.lang-top_select:focus {
  outline: none;
}

.languages__flag img {
  margin: 0 3px;
  max-width: 30px;
}

.add_or_edit_post_language {
  display: flex;
  justify-content: space-between;
}

.languages__flag {
  display: flex;
  justify-content: space-between;
}

.add_or_edit_post_language__single {
  max-width: 30px;
  margin-top: 10px;
  text-align: center;
  min-width: 30px;
}

.language_icon {
  font-size: 14px;
}

.language_icon.language_icon__add {
  color: #42b983;
}

.language_icon.language_icon__edit {
  color: #9c27b0;
}

.v-card__text {
  margin-top: 30px;
}

.category__text-area {
  padding: 12px 0;
}

.category__text-area >>> textarea {
  background-color: rgba(0, 0, 0, 0.06);
  padding: 20px 12px;
}

.category__text-area.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: #42b983;
}

.category__text-area >>> .v-label {
  padding-left: 12px;
  padding-top: 3px;
  height: 30px;
}

.category__text-area >>> .v-label.v-label--active {
  transform: translateY(0px) scale(0.7);
}

.category__text-area >>> .v-label.primary--text {
  color: #42b983!important;
  caret-color: #42b983!important;
}

.category__text-area.v-input .v-label {
  height: 30px;
}

.v-dialog > .v-card > .v-card__text {
  padding: 25px;
}

.alert_notification.success {
  margin-bottom: 0;
}

.notification__popup--alert.success {
  margin-bottom: 30px;
}

.notificationMessage__card--text {
  margin-top: 0;
}

</style>
