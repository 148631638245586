var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[_c('v-app-bar',{attrs:{"app":""}},[_vm._v(" Register ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"label":"Name","required":""},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"E-mail","required":""},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Password","required":""},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}})]}}],null,true)}),_c('div',{staticClass:"user_role"},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.roles,"error-messages":errors,"item-text":"name","item-value":"id","label":"Select role","persistent-hint":"","return-object":"","single-line":""},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})]}}],null,true)})],1),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid}},[_vm._v(" submit ")]),_c('div',{staticClass:"register_error"},[(_vm.error.email !== '' || _vm.error.name !== '')?_c('v-alert',{attrs:{"type":"error"}},[(_vm.error.email !== '')?_c('p',[_vm._v(_vm._s(_vm.error.email))]):_vm._e(),(_vm.error.name !== '')?_c('p',[_vm._v(_vm._s(_vm.error.name))]):_vm._e()]):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }