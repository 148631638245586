<template>
  <div class="edit_user">
    <v-app-bar app>
      Edit User
    </v-app-bar>
    <div>
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <form @submit.prevent="submit">
          <div class="container">
            <div class="row">
              <div class="col-md-8 single_post_box">
                <div class="row">
                  <div class="col-md-12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Name"
                      rules="required|max:255"
                    >
                      <v-text-field
                        v-model="user.name"
                        :counter="255"
                        :error-messages="errors"
                        filled
                        required
                        :value="user.name"
                      ></v-text-field>
                    </validation-provider>
                    <div class="user_role">
                      <h3 class="single_post_box__h3">User role</h3>
                      <v-select
                        v-model="currentRole"
                        :items="roles"
                        item-text="name"
                        item-value="id"
                        label="Select"
                        persistent-hint
                        return-object
                        single-line
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 single_post_sidebar">
                <div class="buttons_section single_post_box">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="invalid"
                  >
                    Update
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>

import { getUser, getAllRoles } from '@/services/user'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { max, required } from 'vee-validate/dist/rules'
import myApi from '@/services/myApi'

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

export default {
  name: 'users',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      userId: this.$route.params.id,
      user: [],
      roles: [],
      currentRole: { id: undefined, name: '' }
    }
  },
  mounted () {
    this.$root.isLoading = true
    getUser(this.userId).then(user => {
      this.user = user
      this.currentRole.id = user.roles[0].id
      this.currentRole.name = user.roles[0].name
    })
    getAllRoles().then(roles => {
      this.roles = roles
    })
    this.$root.isLoading = false
  },
  methods: {
    submit () {
      myApi.post('api/auth/update', {
        user_id: this.userId,
        role_id: this.currentRole.id,
        name: this.user.name
      }).then((response) => {
        if (response.status === 200) {
          this.$router.push({ path: '/users' })
        }
      })
    }
  }
}

</script>

<style scoped>

</style>
