<template>
  <div class="menus">
    <v-app-bar app>
      Menus
    </v-app-bar>
    <div class="row">
      <div class="col-md-8">
        <div class="languages">
          <p class="languages__title">Language:</p>
          <router-link v-for="(item, index) in allLangs"
                       :key="index" class="menu_items"
                       :to="id ? { name: 'menu-items', params: {lang: item, id: id } } : { name: 'menus', params: {lang: item } }">
            {{ item }}
          </router-link>
        </div>
      </div>
      <div class="col-md-4" style="text-align: right">
        <v-btn
          color="#42b983"
          dark
          @click.stop="dialog = true"
        >
          Add menu
        </v-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <v-select
          v-model="selectedMenu"
          @change="changeRoute"
          :items="menus"
          item-text="name"
          item-value="id"
          label="Select Menu"
          persistent-hint
          return-object
          solo
        ></v-select>
      </div>
    </div>
    <router-view></router-view>
    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Add menu
          <v-btn
            color="red"
            @click="dialog = false"
            class="close_btn"
          >
            <font-awesome-icon :icon="['fas', 'times']" class="cursor_pointer" />
          </v-btn>
        </v-card-title>
        <v-card-text>
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <form @submit.prevent="submit">
              <validation-provider
                v-slot="{ errors }"
                name="Name"
                rules="required|max:30"
              >
                <v-text-field
                  v-model="addMenu.name"
                  :counter="255"
                  :error-messages="errors"
                  label="Name"
                  required
                ></v-text-field>
              </validation-provider>
              <v-btn
                color="success"
                type="submit"
                :disabled="invalid"
              >
                Add
              </v-btn>
              <div class="register_error">
                <v-alert v-if="error.name !== ''" type="error">
                  <p>{{error.name}}</p>
                </v-alert>
              </div>
            </form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { getAllMenus } from '@/services/menu'
import { getAllLanguages } from '@/services/getLanguages'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import myApi from '@/services/myApi'

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

export default {
  name: 'menus',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      menus: [],
      menuItems: [],
      lang: this.$route.params.lang,
      id: this.$route.params.id,
      selectedMenu: [],
      allLangs: [],
      dialog: false,
      addMenu: { name: '' },
      error: { name: '' }
    }
  },
  mounted () {
    this.$root.isLoading = true
    getAllMenus().then(menus => {
      if (this.id) {
        menus.map((menu) => {
          if (menu.id === Number(this.id)) {
            this.selectedMenu = menu
          }
        })
      }
      this.menus = menus
    })
    getAllLanguages().then(lang => {
      this.allLangs = lang
    })
    this.$root.isLoading = false
  },
  methods: {
    changeRoute (menus) {
      this.$router.push({ name: 'menu-items', params: { lang: this.lang, id: menus.id } })
    },
    submit () {
      myApi.post('api/menu', {
        name: this.addMenu.name
      }).then((response) => {
        if (response.status === 200) {
          this.dialog = false
          // return message 'Menu created'
        }
      }
      ).catch((error) => {
        this.error.name = ''
        const catchError = error.response.data.errors
        if ('name' in catchError) {
          this.error.name = catchError.name[0]
        }
      })
    }
  },
  watch: {
    '$route.params.id' (newId, oldId) {
      this.id = newId
      this.selectedMenu = newId
    },
    '$route.params.lang' (newLang, oldId) {
      this.lang = newLang
    },
    submit () {
      getAllMenus()
    }
  }
}

</script>

<style scoped>
.register_error {
  margin-top: 20px;
}
.cursor_pointer {
  cursor: pointer;
  color: #fff;
}
.close_btn {
  padding: 0 !important;
  min-width: 36px !important;
  position: absolute;
  right: 10px;
}
.menu_items{
  text-transform: uppercase;
}
.post_form_title {
  border-radius: 10px;
  background-color: #42b983;
  color: white;
  padding: 5px 25px;
}

.single_post_row {
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #f2f2f2;
}

.posts_list__title {
  font-size: 20px;
  font-weight: normal;
}

.post_form_title__columns {
  padding: 12px;
}

.post_form_title__columns p{
  font-size: 20px;
}

p {
  margin-bottom: 0;
}
.languages a {
  padding: 5px 15px;
  display: inline-block;
  border-radius: 5px;
  color: #42b983;
  margin-bottom: 15px;
  text-decoration: none;
  transition: all .3s ease;
}
.languages .router-link-exact-active.router-link-active {
  background-color: #42b983;
  color: #fff;
}

.posts {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
}

.languages__title {
  display: inline-block;
  margin-right: 10px;
}

.posts_list {
  margin-top: -10px;
  padding: 20px;
  border: 2px solid #42b983;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.row.single_post_row {
  margin: 0;
}

.edit_link_p a{
  text-decoration: none;
  font-size: 14px;
  color: #42b983;
}

.pagination {
  padding-top: 30px;
  margin: 0 auto;
  text-align: left;
}

.pagination ul {
  list-style: none;
  padding-left: 0;
}

.pagination ul li {
  display: inline-block;
}

.pagination ul li p {
  padding: 3px 15px;
}

.pagination ul li p.active {
  background-color: #42b983;
  padding: 3px 15px;
  border-radius: 5px;
  color: white;
}

.navbar_icon {
  margin-right: 15px;
}

</style>
