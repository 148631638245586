<template>
  <div class="px-3">
    <div class="metadata-wrapper row">
      <div class="col-12">
        <h3>Kontakt</h3>
        <small><font-awesome-icon :icon="['fas', 'info']" /> Ovo je deo vezan za društvene mreže i kontakt podatke.</small> <br />
      </div>

      <div class="mt-1 col-6">
        <small class="mr-1"><font-awesome-icon :icon="['fas', 'phone']" /> Br telefona:</small>
        <input class="input" type="text" placeholder="Br telefona" v-model="metaData.contact.phone" />
      </div>
      <div class="mt-1 col-6">
        <small class="mr-1"><font-awesome-icon :icon="['fas', 'map']" /> Adresa:</small>
        <input class="input" type="text" placeholder="Adresa"  v-model="metaData.contact.address" />
      </div>
      <div class="mt-1 col-6">
        <small class="mr-1"><font-awesome-icon :icon="['fas', 'globe']" /> Vebsajt:</small>
        <input class="input" type="text" placeholder="Vebsajt"  v-model="metaData.contact.website" />
      </div>
      <div class="mt-1 col-6">
        <small class="mr-1">
          <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
            Imejl:</small>
        <input class="input" type="text" placeholder="Email"  v-model="metaData.contact.email" />
      </div>
      <div class="mt-1 col-6">
        <small class="mr-1"><font-awesome-icon :icon="['fas', 'map']" /> Koordinate na mapi:</small>
        <input class="input" type="text" placeholder="Koordinate"  v-model="metaData.contact.coordinates" />
      </div>
      <div class="mt-1 col-12 social">
        <small class="mr-1"><font-awesome-icon :icon="['fas', 'globe']" /> Društvene mreže:</small>
      </div>
      <div class="mt-1 col-6 social">
        <small class="mr-1">Facebook:</small>
        <input class="input" type="text" placeholder="Facebook"  v-model="metaData.contact.socialMedia.facebook" />
      </div>
      <div class="mt-1 col-6 social">
        <small class="mr-1">Twitter:</small>
        <input class="input" type="text" placeholder="Twitter"  v-model="metaData.contact.socialMedia.twitter" />
      </div>
      <div class="mt-1 col-6 social">
        <small class="mr-1">Instagram:</small>
        <input class="input" type="text" placeholder="Instagram"  v-model="metaData.contact.socialMedia.instagram" />
      </div>
    </div>
    <div class="metadata-wrapper mt-6 row">
      <div class="col-12">
        <h3>Povezane objave</h3>
        <small><font-awesome-icon :icon="['fas', 'info']" /> Ovo je deo vezan za objave koje su povezane.</small> <br />
        <small>Sažeti deo, sa svega par informacija</small>
      </div>
      <div class="col-12">
        <ul class="related-row col-6">
          <li class="mb-5" v-for="(post, i) in metaData.releatedPosts" :key="i">
            <input class="input" placeholder="Ime" type="text" v-model="post.name" />
            <span class="delete-row" @click="() => deleteRow(i)"><font-awesome-icon :icon="['fas', 'trash']" /></span>
            <ul class="mt-2 sub-menu">
              <li class="mb-1" v-for="(item, index) in post.subitems" :key="index">
                <span @click="() => deleteSubRow(i , index)" class="delete-sub-row">{{  }}<font-awesome-icon :icon="['fas', 'trash']" /></span>
                <textarea style="max-height: 100px;" class="input" placeholder="Sadrižaj" type="text" v-model="item.content"></textarea>
              </li>
               <div style="line-height: 0.5;
                width: 100%;
                height: 14px;
                font-size: 10px;" class="button_style_1 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default" @click="addSubRowItem(i)">Dodaj novi red</div>
            </ul>
          </li>
        </ul>
      </div>
      <div class="col-12">
        <div style="line-height: 2;" class="button_style_1 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default" @click="addRelatedPostsRow">Dodaj novi red</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['metadata'],
  data () {
    return {
      metaData: {
        contact: {
          phone: '',
          address: '',
          website: '',
          email: '',
          coordinates: '',
          socialMedia: {
            facebook: '',
            twitter: '',
            instagram: ''
          }
        },
        releatedPosts: []
      }
    }
  },
  watch: {
    metadata: function (newVal, oldVal) {
      this.metaData = {
        ...this.metaData,
        ...newVal
      }
    }
  },
  methods: {
    addRelatedPostsRow () {
      this.metaData.releatedPosts.push({
        name: '',
        subitems: [{
          content: ''
        }]
      })
    },
    deleteRow (index) {
      this.metaData.releatedPosts = this.metaData.releatedPosts.filter((_, i) => i !== index)
    },
    deleteSubRow (mainIndexToRemove, subIndexToRemove) {
      this.metaData.releatedPosts[mainIndexToRemove].subitems = this.metaData.releatedPosts[mainIndexToRemove].subitems.filter((_, i) => i !== subIndexToRemove)
    },
    addSubRowItem (parentIndex) {
      this.metaData.releatedPosts[parentIndex].subitems.push({
        content: ''
      })
    },
    save () {
      this.$emit('onSave', this.metaData)
    },
    metadataCallback () {
      this.save()
    }
  }
}
</script>

<style scoped>
.metadata-wrapper {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px 10px;
}
.input {
      color: rgba(0, 0, 0, 0.87);
          flex: 1 1 auto;
    line-height: 20px;
    padding: 8px 0 8px;
    max-width: 100%;
    width: 100%;
        max-height: 32px;
        border: 1px solid rgba(0, 0, 0, 0.87);
        border-radius: 5px;
        padding: 2px;
}
.related-row {
  padding: 0;
  margin: 0;
  list-style: none;
}
.related-row li {
  position: relative;
}
.delete-row {
  position: absolute;
  top: -20px;
  right: -20px;
  background-color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.delete-row svg path {
  fill: white;
  width: 10px;
}
.sub-menu {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.sub-menu li {
  display:flex;
}
.delete-sub-row {
  width: 20px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  padding: 0 15px;
  cursor: pointer;
}
.delete-sub-row svg path {
  fill: white;
  width: 10px;
}
.sub-menu input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
