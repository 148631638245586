import myApi from '@/services/myApi'

export function getAllCategories (lang, page) {
  return myApi.get('api/' + lang + '/category?page=' + page)
    .then(categories => categories)
}

export function getSingleCategory (lang, id) {
  return myApi.get('api/' + lang + '/category/' + id)
    .then(category => {
      return category.data
    })
}

export function deleteSingleCategory (id) {
  return myApi.delete('/api/en/category/' + id).then(response => console.log(response))
}
